<div class="container-fluid">
  <app-pagetitle title="Questionnaire"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" (ngSubmit)="post()" [formGroup]="form">
            <div class="row">
              <div class="col-md-6">
                <label for="">Espace source *</label>
                <select class="form-control" name="source_id" formControlName="source">
                  <option *ngFor="let q of quizzes" [value]="q.id">
                    {{ q.name}}
                  </option>
                </select>
                <div *ngIf="submit && f.source.errors" class="invalid-feedback">
                  <span *ngIf="f.source.errors.required">Champ obligatoire.</span>
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Espace destination *</label>
                <select class="form-control" name="dest_id" formControlName="dest">
                  <!-- <option value="-1">Tous les espaces</option>   -->
                  <option *ngFor="let q of quizzes" [value]="q.id">
                    {{ q.name}}
                  </option>
                </select>
                <div *ngIf="submit && f.dest.errors" class="invalid-feedback">
                  <span *ngIf="f.dest.errors.required">Champ obligatoire.</span>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-4 ">
                <div class="custom-control custom-switch mb-4">
                  <input type="checkbox" id="budget" formControlName="budget" class="custom-control-input">
                  <label for="budget" class="custom-control-label">Budget</label>
                </div>
              </div>
            </div>
            <div class="text-right mt-5">
              <button class="btn btn-primary jet-font" type="submit" [disabled]="form.invalid">
                <i class="fas fa-paper-plane"></i>
                Copier</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
