<div class="container-fluid" *ngIf="project">
  <app-pagetitle title="Projet : {{project.reference }} - {{ project.created_at | date}} - {{project.title}}">
  </app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="project.status == 'completed'">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-auto">
                <h5 class="font-size-13 ">Statut du projet : </h5>
              </div>
              <div class="col">
                <h5 class="font-size-13 ">{{getStatusName(project.status)}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <h5 class="font-size-13 ">Utilisateur : </h5>
              </div>
              <div class="col">
                <h5 class="font-size-13 ">{{project.customer.fullname}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul ngbNav #justifiednav="ngbNav" [activeId]="sectionId" class="nav-pills nav-justified">
              <li [ngbNavItem]="0">
                <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 0}"
                  [style.background-color]="sectionId == 0 ? settings.primary_color : ''" (click)="selectTab( 0 )">
                  <span class="d-block d-sm-none" [style.color]="sectionId == 0 && light == true ? 'black' : ''"><i
                      class="fas fa-camera"></i></span>
                  <span class="d-none d-sm-block"
                    [style.color]="sectionId == 0 && light == true ? 'black' : ''">Brief</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="card" *ngIf="project.response_description">
                    <div class="card-body" style="white-space: pre-wrap;">
                      {{ project.response_description }}
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="1">
                <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 1}"
                  [style.background-color]="sectionId == 1 ? settings.primary_color : ''" (click)="selectTab( 1 )">
                  <span class="d-block d-sm-none" [style.color]="sectionId == 1 && light == true ? 'black' : ''"><i
                      class="fas fa-camera"></i></span>
                  <span class="d-none d-sm-block" [style.color]="sectionId == 1 && light == true ? 'black' : ''">Visuels
                    3D</span>
                </a>
                <ng-template ngbNavContent>
                  <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let photo of project.photos">
                      <div class="photo">
                        <img [src]="photo.url" alt="Random first slide" />
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 2}"
                  [style.background-color]="sectionId == 2 ? settings.primary_color : ''" (click)="selectTab( 2 )">
                  <span class="d-block d-sm-none" [style.color]="sectionId == 2 && light == true ? 'black' : ''"><i
                      class="fas fa-video"></i></span>
                  <span class="d-none d-sm-block" [style.color]="sectionId == 2 && light == true ? 'black' : ''">Visite
                    360°</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="card">
                    <iframe id="i_frame" about="_blank" [src]='visitURL' width="100%" height="750px" title="Rendu 3D">
                    </iframe>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 3}"
                  [style.background-color]="sectionId == 3 ? settings.primary_color : ''" (click)="selectTab( 3 )">
                  <span class="d-block d-sm-none" [style.color]="sectionId == 3 && light == true ? 'black' : ''"><i
                      class="fas fa-shopping-cart"></i></span>
                  <span class="d-none d-sm-block"
                    [style.color]="sectionId == 3 && light == true ? 'black' : ''">Shopping
                    list</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="card" style="padding: 10px;" *ngIf="project.products.length > 0">
                    <div class="card-body">

                      <div class="row">
                        <div class="col-3" *ngFor="let product of project.products">
                          <div class="produit">

                            <div class="alert">
                              <div>
                                <i class="fa fa-check-circle"></i> <br>
                                Produit ajouté
                              </div>
                            </div>

                            <div class="heading">

                              <div class="img">
                                <img src="{{ product.photo_uri }}" alt="">
                                <a href="{{product.link}}" target="_blank" class="savoir-plus">En savoir plus</a>
                              </div>
                            </div>
                            <div class="foot">
                              <div class="info">
                                <b>{{product.name}}</b>
                                <p>{{product.reference}}</p>
                                <p class="dimensions font-size-11 mt-2">
                                  {{product.largeur ? 'L.' +product.largeur :''}}
                                  {{product.largeur && (product.hauteur || product.profondeur) ? 'x ':'' }}
                                  {{product.hauteur ? 'H.' +product.hauteur :''}}
                                  {{product.hauteur && product.profondeur ? 'x ':'' }}
                                  {{product.profondeur ? 'P.' +product.profondeur:''}}
                                </p>
                              </div>
                              <p class="prix_public font-weight-bold mt-4 mb-0">Prix public {{ product.price }} €</p>
                            </div>
                            <div class="hover">
                              <a *ngIf="product.link" href="{{product.link}}" target="_blank"
                                class="btn-voir-produit  btn-formulaire">Voir le produit</a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </ng-template>
              </li>

            </ul>
            <div class="pb-5" [ngbNavOutlet]="justifiednav"></div>
          </div>
        </div>
      </div>
      <div *ngIf="project.status == 'pending' || project.status == 'in-progress'">
        <div class="card text-center w-full">
          <div class="card-body pt-5 pb-5">
            Projet en cours de traitement
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
