<div class="p-5 mt-50 text-center bg-white">
    <div class="container">
      <a href="javascript:void(0);" (click)="this.navigation();" class="logo" *ngIf="(settings && settings.logo);">
        <img [src]="settings.logo" height="75" alt="logo">
      </a>
    </div>
    <div class="mt-50">
        <h2 class="h3">Commande annulée !</h2>
    </div>
    <div class="mt-50">
        <button type="button" class="btn btn-primary w-md waves-effect waves-light" (click)="this.navigation();">Revenir à votre espace</button>
    </div>
</div>