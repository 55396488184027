import { Component, OnInit } from '@angular/core';

import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']

})
export class UsersComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  users: any[];
  term: string;
  user: any;

  constructor(
    public ws: WS,
    private authFackservice: AuthfakeauthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.authFackservice.currentUserValue;
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Utilisateurs', active: true }];

    this.ws.get('customers')
      .subscribe(
        data => { this.users = data;}
      );
  }

  connectAs(id): void {
    this.authFackservice.connectAs(id)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        });
  }

}
