<div class="container-fluid">
  <div class="row mb-5">
    <div class="col">
      <app-pagetitle title="Questionnaire - {{quiz.name}}"></app-pagetitle>
    </div>
    <div class="col-auto">
      <app-quiz-colors-configurator></app-quiz-colors-configurator>
    </div>
  </div>

  <ul ngbNav #justifiednav="ngbNav" [activeId]="quizSectionId" class="nav-pills nav-justified">

    <li [ngbNavItem]="section.id" *ngFor="let section of quiz.sections; let i=index">
      <a ngbNavLink class="mb-3" (click)="selectTab( section.id )">
        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
        <span class="d-none d-sm-block">{{ section.name }}</span>
      </a>
      <ng-template ngbNavContent>

        <div *ngIf="section.questions.length else question">
          <div class="question" *ngFor="let question of sortBy(section.questions, 'ordre')">
            <div class="delete" (click)="deleteQuestion(quiz.id, question.id)">
              <i class="fa fa-trash"></i>
            </div>

            <div class="ordering">
              <div class="up" (click)="orderChange(question,-1)"><i class="fa fa-arrow-up"></i></div>
              <div class="down"  (click)="orderChange(question,1)"><i class="fa fa-arrow-down"></i></div>
            </div>

            <app-quiz-title *ngIf="question.type === 'title'" [question]="question"></app-quiz-title>
            <app-quiz-qcm *ngIf="question.type === 'radio' || question.type ===  'checkbox'" [question]="question" [questions]="section.questions">
            </app-quiz-qcm>
            <app-quiz-rangeslider *ngIf="question.type === 'slider'" [question]="question"></app-quiz-rangeslider>
            <app-quiz-textarea *ngIf="question.type === 'textarea'" [question]="question"></app-quiz-textarea>
            <app-quiz-files *ngIf="question.type === 'photos' || question.type === 'video'" [question]="question"></app-quiz-files>
            <app-quiz-links *ngIf="question.type === 'links'" [question]="question"></app-quiz-links>
            <app-quiz-rank *ngIf="question.type === 'rank'" [question]="question"></app-quiz-rank>
          </div>
          <div class="text-center">
            <button (click)="modal(question)" class="btn btn-default btn-rounded bg-white text-info mb-4">
              <i class="fa fa-plus mr-2"></i> Nouvelle question
            </button>
          </div>
        </div>

      </ng-template>
    </li>

  </ul>
  <div [ngbNavOutlet]="justifiednav"></div>
</div>

<ng-template #question let-modal>

  <div class="modal-body">
    <div class="questions-chooser">
      
      <div class="item" (click)="addQuestion('title')">
        <div class="icon">
          <img src="assets/images/icons/Titre.png" alt="">
        </div>
        <label>Titre</label>
      </div>
      
      <div class="item" (click)="addQuestion('radio')">
        <div class="icon">
          <img src="assets/images/icons/Choix multiples.png" alt="">
        </div>
        <label>QCM/QCU</label>
      </div>
      
      <div class="item" (click)="addQuestion('slider')">
        <div class="icon">
          <img src="assets/images/icons/Intervalle.png" alt="">
        </div>
        <label>Intervalle</label>
      </div>
      
      <div class="item" (click)="addQuestion('textarea')">
        <div class="icon">
          <img src="assets/images/icons/Question ouverte.png" alt="">
        </div>
        <label>Ouverte</label>
      </div>
      
      <div class="item" (click)="addQuestion('photos')">
        <div class="icon">
          <img src="assets/images/icons/Telecharger fichier.png" alt="">
        </div>
        <label>Fichiers</label>
      </div>
      
      <div class="item" (click)="addQuestion('links')">
        <div class="icon">
          <img src="assets/images/icons/Lien url.png" alt="">
        </div>
        <label>Liens</label>
      </div>
      
      <div class="item" (click)="addQuestion('rank')">
        <div class="icon">
          <img src="assets/images/icons/Evaluation.png" alt="">
        </div>
        <label>Évaluation</label>
      </div>

    </div>

  </div>
</ng-template>
