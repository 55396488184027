import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eror-modal',
  templateUrl: './eror-modal.component.html',
  styleUrls: ['./eror-modal.component.scss']
})
export class ErorModalComponent {
  public message: string;
  constructor(public modalRef: NgbActiveModal) { }
}
