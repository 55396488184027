import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { WS } from 'src/app/core/services/ws.service';
import { MustMatch } from './validation.mustmatch';
import { NgSelectConfig } from '@ng-select/ng-select';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-compte',
  templateUrl: './compte.component.html',
  styleUrls: ['./compte.component.scss']
})
export class CompteComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  form: FormGroup;
  formConnexion: FormGroup;
  user: any;
  isCollapsed: boolean;
  isActiveAdress: boolean;
  isActiveNotifs: boolean;
  isActivePayment: boolean;
  // Form submition
  submit: boolean;
  submitFC: boolean;
  settings: any = {};
  FactureValue: string[] = [];
  fileData: File = null;
  previewUrl: any = null;
  dataFacture: any = {}
  notFoundText = 'Aucun élément';

  isActiveInfo: boolean;
  isActivePwd: boolean;
  formInfo: FormGroup;
  formPwd: FormGroup;
  formNotifs: FormGroup;
  formPayment: FormGroup;
  submitInfo: any;
  adress: any = null;
  sectionId = 0;

  constructor(
    public formBuilder: FormBuilder,
    private authFackservice: AuthfakeauthenticationService,
    public ws: WS,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = this.notFoundText;
  }

  ngOnInit(): void {

    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Compte', active: true }];
    this.getSettings();

    this.isActiveInfo = false;
    this.isActivePwd = false;
    this.isActiveAdress = false;
    this.isActiveNotifs = false;
    this.isActivePayment = false;

    this.formInfo = this.formBuilder.group({
      email: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required, Validators.email]],
      firstName: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required]],
      lastName: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required]],
    });

    this.formPwd = this.formBuilder.group({
      oldpassword: [{ value: '', disabled: !this.isActivePwd }, Validators.required],
      password: [{ value: '', disabled: !this.isActivePwd }, [Validators.required, Validators.minLength(6)]],
      confirmpwd: [{ value: '', disabled: !this.isActivePwd }, Validators.required]
    }, {
      validator: MustMatch('password', 'confirmpwd'),
    });

    this.formNotifs = this.formBuilder.group({
      notif_new_project: [{ value: '', disabled: !this.isActiveNotifs }],
      notif_delivred_project: [{ value: '', disabled: !this.isActiveNotifs }]
    })
    this.formPayment = this.formBuilder.group({
      enabled_payment: [{ value: '', disabled: !this.isActivePayment }],
      cost_project: [{ value: '', disabled: !this.isActivePayment }]
    })
    this.getConnectedUser();
    this.getAddress();


    this.form = this.formBuilder.group({
      firstname: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      lastname: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      phone: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[0-9]+')]],
      company: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required]],
      email: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      city: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required,]],
      zip: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      country: [{ value: '', disabled: !this.isActiveAdress }, [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    });



  }

  get f() {
    return this.form.controls;
  }

  get fC() {
    return this.formPwd.controls;
  }
  get fInfo() { return this.formInfo.controls; }

  get fSettings() { return this.formNotifs.controls; }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
          if (data.logo) {
            this.previewUrl = data.logo;
          }
          if (!data) {
            this.settings = {};
          }
        }
      );
  }

  updateNotifs(): void {
    if (!this.isActiveNotifs) {
      this.isActiveNotifs = true;
    } else {
      this.ws.put('settings/payment', { notif_new_project: this.settings.notif_new_project, notif_delivred_project: this.settings.notif_delivred_project})
        .subscribe(
          data => {
            this.isActiveNotifs = false;
            Swal.fire({
              title: 'Modification effectuée avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            });
          }
        )
    }
  }

  updatePayment(): void {
    if (!this.isActivePayment) {
      this.isActivePayment = true;
    } else {
      this.ws.put('settings/payment', { enabled_payment: this.settings.enabled_payment, cost_project: this.settings.cost_project})
        .subscribe(
          data => {
            this.isActivePayment = false;
            Swal.fire({
              title: 'Modification effectuée avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            });
          }
        )
    }
  }

  getAddress(): void {
    this.ws.get('settings/address/' + this.user.id)
      .subscribe(
        data => {
          this.adress = data;
          if (data) {
            this.form.patchValue({
              email: this.adress.email,
              firstname: this.adress.firstname,
              lastname: this.adress.lastname,
              phone: this.adress.phone,
              company: this.adress.company,
              city: this.adress.city,
              zip: this.adress.zip,
              country: this.adress.country,
            });
          }
        }
      );
  }

  post() {
    if (!this.isActiveAdress) {
      this.formDisable(this.form, 'enable');
      this.isActiveAdress = true;
    } else {
      this.submit = true;
      if (this.form.invalid) {
        return;
      }
      this.ws.post('settings/address/' + this.user.id, this.form.value)
        .subscribe(
          data => {
            this.formDisable(this.form, 'disable');
            this.isActiveAdress = false;
            this.dataFacture = data;
            if (this.dataFacture.success) {
              Swal.fire({
                title: 'Modification effectuée avec succès.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Erreur de mise à jour, veuillez réessayer ultérieurement.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
              // this.toastr.error("Une erreur s'est produite.Veuillez réessayer ultérieurement");
            }
          }
        )
    }
  }



  // updatePassword() {
  //   this.submitFC = true;
  //   this.ws.put('customers/password/' + this.user.id, this.formConnexion.value)
  //     .subscribe(
  //       data => {
  //         console.log(data);
  //       }
  //     )
  // }

  savePhoto() {
    const formData = new FormData();
    formData.append('logo', this.fileData);

    this.ws.post('settings/logo', formData)
      .subscribe(
        data => {
          window.location.reload();
        }
      )
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.savePhoto();
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  selectTab(sectionId: number): void {
    this.sectionId = sectionId;
  }

  getConnectedUser(): void {
    this.user = this.authFackservice.currentUserValue;
    this.formInfo.patchValue({
      email: this.user.email,
      firstName: this.user.firstname,
      lastName: this.user.lastname,
    });
  }

  formDisable(form, action): void {
    if (action == 'enable') {
      Object.keys(form.controls).forEach(key => {
        form.get(key).enable();
      });
    } else {
      Object.keys(form.controls).forEach(key => {
        form.get(key).disable();
      });
    }
  }

  postInfo() {
    if (!this.isActiveInfo) {
      this.formDisable(this.formInfo, 'enable');
      this.isActiveInfo = true;
    } else {
      this.submitInfo = true;
      if (this.formInfo.invalid) {
        return;
      }

      this.submitInfo = true;
      this.ws.put('customers/' + this.user.id, this.formInfo.value)
        .subscribe(
          (data) => {
            this.user = data;
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.formInfo.patchValue({
              email: this.user.email,
              firstName: this.user.firstname,
              lastName: this.user.lastname,
            });
            this.formDisable(this.formInfo, 'disable');
            this.isActiveInfo = false;
            Swal.fire({
              title: 'Modification effectuée avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            })
          },
          (error) => {
            if (error == "Email existant.") {
              Swal.fire({
                title: 'Email existant.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Erreur de mise à jour, veuillez réessayer ultérieurement.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
            }
          }
        )
    }
  }
  postPwd(): void {
    if (!this.isActivePwd) {
      this.isActivePwd = true;
      this.formDisable(this.formPwd, 'enable');
    } else {
      this.submitFC = true;
      if (this.formPwd.invalid) {
        return;
      }

      this.submitFC = true;
      this.ws.put('customers/passwords/' + this.user.id, this.formPwd.value)
        .subscribe(
          (data) => {
            this.isActivePwd = false;
            this.formDisable(this.formPwd, 'disable');
            // this.toastr.success('Mot de passe mis à jour avec succès.');
            Swal.fire({
              title: 'Mot de passe mis à jour avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            });
          },
          (error) => {
            if (error == "Ancien mot de passe.") {
              Swal.fire({
                title: 'Veuillez vérifier votre ancien mot de passe.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
              // this.toastr.error('Veuillez vérifier votre ancien mot de passe.');
            } else {
              Swal.fire({
                title: 'Erreur de mise à jour, veuillez réessayer ultérieurement.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
              // this.toastr.error('Erreur de mise à jour, veuillez réessayer ultérieurement.');
            }
          }
        )
    }

  }
}
