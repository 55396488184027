import { Component, OnInit, Input } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-quiz-rank',
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss']
})
export class RankComponent implements OnInit {

  @Input() question: any;

  constructor(public ws: WS) { }
  ngOnInit(): void {
  }

  save(): void {
    console.log(this.question.options);
    this.ws.put('questions/' + this.question.id, this.question)
      .subscribe(data => {
        // this.toastr.success('Mise à jour avec succès');
        // console.log(data);
      });
  }
  getQuestionType(type): string{
    return {
      'title':'Titre',
      'radio':'QCM / QCU',
      'slider':'Intervalle',
      'textarea':'Ouverte',
      'photos':'Fichiers',
      'links':'Liens',
      'rank':'Évaluation'
    }[type];
  }
}
