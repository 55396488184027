import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsQuizComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  quiz: any = {};
  primaryColor: string;
  secondaryColor: string;
  id: number;
  quizSectionId: number;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public ws: WS) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id * 1;
    });


    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Questionnaire', active: true }];
    this.getQuiz();
  }

  getQuiz() {
    this.ws.get('quiz/' + this.id)
      .subscribe(
        data => {
          this.quiz = data;
          if (!this.quizSectionId) {
            this.quizSectionId = this.quiz.sections[0].id;
          }
        }
      );
  }
  modal(Data: any) {
    this.modalService.open(Data, { centered: true, size: 'xl' });
  }
  addQuestion(type): void {
    this.ws.post('quiz', { quiz_id: this.id, type, quiz_section_id: this.quizSectionId })
      .subscribe(res => {
        this.getQuiz();
        this.modalService.dismissAll();
      });
  }
  deleteQuestion(quizId , questionId): void {
    this.ws.delete('questions/' + quizId + '/' + questionId)
      .subscribe(res => {
        this.getQuiz();
      });
  }
  selectTab(quizSectionId): void {
    this.quizSectionId = quizSectionId;
  }

  orderChange(question, direction): void {
   this.ws.put('quiz/'+this.quiz.id +'/'+ this.quizSectionId +'/'+ question.id+'/'+direction , {})
      .subscribe(res => {
        this.getQuiz();
      });
  }

  

  sortBy(list :any, prop: string) {
    return list.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }


}
