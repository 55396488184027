import { Component, Input, OnInit } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quiz-qcm',
  templateUrl: './qcm.component.html',
  styleUrls: ['./qcm.component.scss']
})
export class QcmComponent implements OnInit {

  @Input() question: any;
  @Input() questions: any;
  uploadForm: FormGroup
  fileData: File = null;
  previewUrl: any = [];
  files: any = [];
  indexFiles: any = [];
  quiz_id: number;

  reponses: any[] = [{}, {}];

  constructor(
    public ws: WS,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.quiz_id = params.id * 1;
    });

    if (this.question) {
      if( this.questions ){
        this.questions = this.questions.filter((q)=>{
          return q.id != this.question.id
        });
      }
      this.reponses = this.question.content.responses;
      if (!this.question.options || this.question.options.length == 0) {
        this.question.options = {};
      }
    }
  }

  addResponse(): void {
    this.reponses.push({});
  }
  deleteResponse(index): void {
    this.reponses.splice(index, 1);
  }
  onFileChanged(fileInput: any, index: number) {
    this.fileData = <File>fileInput.target.files[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl[index] = reader.result;
      this.files[index] = reader.result;
      this.indexFiles[index] = index;
    }
  }

  save(): void {
    console.log(this.question);
    const formData = new FormData();
    this.files.forEach((image) => {
      formData.append('file[]', image);
    });
    this.indexFiles.forEach((index) => {
      formData.append('indexFile[]', index);
    });
    formData.append('question', JSON.stringify(this.question));

    let headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };

    this.ws.post('questions/storeQCM/' + this.question.id+'/'+this.quiz_id, formData, options)
      .subscribe(res => {
        // this.toastr.success('Mise à jour avec succès');
        // console.log(res);
      });
  }
  getQuestionType(type): string{
    return {
      'title':'Titre',
      'radio':'QCM / QCU',
      'slider':'Intervalle',
      'textarea':'Ouverte',
      'photos':'Fichiers',
      'links':'Liens',
      'rank':'Évaluation'
    }[type];
  }
}


