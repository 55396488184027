<form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="form">
  <div class="form-group">
    <input type="email" class="form-control" formControlName="email" placeholder="Email" />
  </div>
  <div class="form-group">
    <input type="text" class="form-control" formControlName="object" placeholder="Object" />
  </div>
  <div class="form-group">
    <textarea rows="8" class="form-control" placeholder="Veuillez détailler la raison de la prise en contact "></textarea>
  </div>

  <div class="text-right">
    <button class="btn btn-light mr-2" type="reset">Annuler</button>
    <button class="btn btn-primary ml-2" type="submit">Envoyer</button>
  </div>
  <div>
    <p class="font-size-12 mt-2">Vous pouvez aussi nous joindre par téléphone au XXX ou par email XXX</p>
  </div>
</form>
