import { Component, OnInit } from '@angular/core';

import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from '../../../../core/services/authfake.service';

@Component({
  selector: 'app-architect',
  templateUrl: './architect.component.html',
  styleUrls: ['./architect.component.scss']
})
export class ArchitectComponent implements OnInit {
  projects: any[];
  term: string;
  user: any;
  constructor(
    public ws: WS,
    private authFackservice: AuthfakeauthenticationService
  ) {
  }
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.user = this.authFackservice.currentUserValue;

    this.ws.get('projects/list/' + this.user.id)
      .subscribe(
        data => this.projects = data
      );
  }

  getStatusName(status): string{
    return {
      'new':'Nouveau',
      'draft':'Brouillon',
      'sended':'Envoyé',
      'in-progress' : 'En cours',
      'pending' : 'Vérification',
      'completed':'Terminé'
    }[status];
  }
}
