import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  form: FormGroup; // bootstrap validation form
  submit: boolean;
  breadCrumbItems: Array<{}>;
  nom: string;
  iframeScript: string;
  settings: any = {};

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public ws: WS,) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'intégration', active: true }];
    this.getSettings();
    this.form = this.formBuilder.group({

      pipcke_subdomain: ['', [Validators.pattern('[a-zA-Z0-9]+')]],
      subdomain: ['', [Validators.pattern('[a-zA-Z0-9]+')]]
    });

    this.iframeScript = '<iframe frameborder="0" width="100%" height="2000px" src="' + window.location.origin + '" title="Koachy" style="border:0px;"></iframe>';
  }

  validSubmit() {
    this.ws.put('settings/payment', { sub_domain: this.settings.sub_domain, domaine_name: this.settings.domaine_name })
      .subscribe(
        data => {

        }
      );
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
          if (!data) {
            this.settings = {};
          }
        }
      );
  }

  modal(Data: any) {
    this.modalService.open(Data, { centered: true, size: 'lg' });
  }

  copyToClipboard(text) {
    window.prompt("Copier: Ctrl+C", text);
  }

}
