import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { WS } from 'src/app/core/services/ws.service';
import { MustMatch } from '../compte/validation.mustmatch';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: any = null;
  formInfo: FormGroup;
  formPwd: FormGroup;
  formAdress: FormGroup;
  submitInfo: any;
  submitFC: any;
  isCollapsedInfo: boolean;
  isCollapsedPwd: boolean;
  isActiveInfo: boolean;
  isActivePwd: boolean;

  constructor(public ws: WS,
    public authFackservice: AuthfakeauthenticationService,
    private router: Router,
    public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.isActiveInfo = false;
    this.isActivePwd = false;

    this.formInfo = this.formBuilder.group({
      email: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required, Validators.email]],
      firstName: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required]],
      lastName: [{ value: '', disabled: !this.isActiveInfo }, [Validators.required]],
    });

    this.getConnectedUser();

    this.formPwd = this.formBuilder.group({
      oldpassword: [{ value: '', disabled: !this.isActivePwd }, Validators.required],
      password: [{ value: '', disabled: !this.isActivePwd }, [Validators.required, Validators.minLength(6)]],
      confirmpwd: [{ value: '', disabled: !this.isActivePwd }, Validators.required]
    }, {
      validator: MustMatch('password', 'confirmpwd'),
    });

    this.isCollapsedInfo = false;
    this.isCollapsedPwd = true;
  }

  get fInfo() { return this.formInfo.controls; }
  get fC() {
    return this.formPwd.controls;
  }
  getConnectedUser(): void {
    this.ws.get('authenticated')
      .subscribe(
        data => {
          this.user = data;
          this.formInfo.patchValue({
            email: this.user.email,
            firstName: this.user.firstname,
            lastName: this.user.lastname,
          });
        })
  }

  logout(): void {
    this.authFackservice.logout();
    this.router.navigate(['/compte/connexion']);
  }

  postPwd(): void {
    if (!this.isActivePwd) {
      this.formDisable(this.formPwd, 'enable');
      this.isActivePwd = true;
    } else {
      this.submitFC = true;
      if (this.formPwd.invalid) {
        return;
      }
      this.ws.put('customers/passwords/' + this.user.id, this.formPwd.value)
        .subscribe(
          (data) => {
            this.formDisable(this.formPwd, 'disable');
            this.isActivePwd = false;
            // this.toastr.success('Mot de passe mis à jour avec succès.');
            Swal.fire({
              title: 'Mot de passe mis à jour avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            });
          },
          (error) => {

            if (error == "Ancien mot de passe.") {
              Swal.fire({
                title: 'Veuillez vérifier votre ancien mot de passe.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
              // this.toastr.error('Veuillez vérifier votre ancien mot de passe.');
            } else {
              Swal.fire({
                title: 'Erreur de mise à jour, veuillez réessayer ultérieurement.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
              // this.toastr.error('Erreur de mise à jour, veuillez réessayer ultérieurement.');
            }
          }
        )
    }
  }
  postInfo(): void {
    if (!this.isActiveInfo) {
      this.formDisable(this.formInfo, 'enable');
      this.isActiveInfo = true;
    } else {
      this.submitInfo = true;
      if (this.formInfo.invalid) {
        return;
      }
      this.ws.put('customers/' + this.user.id, this.formInfo.value)
        .subscribe(
          (data) => {
            this.formDisable(this.formInfo, 'disable');
            this.isActiveInfo = false;
            Swal.fire({
              title: 'Informations mises à jour avec succès.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            });
            // this.toastr.success('Informations mises à jour avec succès.');
          },
          (error) => {
            if (error == "Email existant.") {
              // this.toastr.error('Email existant, veuillez utiliser un autre email.');
              Swal.fire({
                title: 'Email existant, veuillez utiliser un autre email.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Erreur de mise à jour, veuillez réessayer ultérieurement.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              });
            }
            this.getConnectedUser();
          }
        )
    }
  }
  formDisable(form, action): void {
    if (action == 'enable') {
      Object.keys(form.controls).forEach(key => {
        form.get(key).enable();
      });
    } else {
      Object.keys(form.controls).forEach(key => {
        form.get(key).disable();
      });
    }
  }

}
