<div class="container-fluid pt-3" *ngIf="user">
  <div class="row jet-font">
    <div class="col">
      <a class="btn btn-profile" [routerLink]="['/']">
        <span class="d-block d-sm-none"><i class="fas fa-list-alt"></i></span>
        <span class="d-none d-sm-block"><i class="fas fa-list-alt"></i>Liste des projets</span>
      </a>
    </div>
    <div class="col-auto text-right">
      <div (click)="logout()" class="btn btn-logout">
        <span class="d-block d-sm-none"><i class="fas fa-sign-out-alt"></i></span>
        <span class="d-none d-sm-block"><i class="fas fa-sign-out-alt"></i>Déconnexion</span>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid" *ngIf="project">
  <div>
    <div class="card mt-4">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <h5 class="font-size-13 ">Statut du projet : </h5>
          </div>
          <div class="col">
            <h5 class="font-size-13 ">{{getStatusName(project.status)}}</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ul ngbNav #justifiednav="ngbNav" [activeId]="sectionId" class="nav-pills nav-justified">
          <li [ngbNavItem]="0">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 0}"
              [style.background-color]="sectionId == 0 ? settings.primary_color : ''" (click)="selectTab( 0 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 0 && light == true ? 'black' : ''"><i
                  class="fas fa-comments"></i></span>
              <span class="d-none d-sm-block"
                [style.color]="sectionId == 0 && light == true ? 'black' : ''">Brief</span>
            </a>
            <ng-template ngbNavContent>
              <p class="text-center title-chat d-block d-sm-none" [style.color]="light == true ? 'black' : 'white'"
              [style.background-color]="settings.primary_color ? settings.primary_color : ''">Brief
              </p>
              <div class="card" *ngIf="project.status == 'completed' && project.response_description">
                <div class="card-body" style="white-space: pre-wrap;">
                  {{ project.response_description }}
                </div>
              </div>
              <div *ngIf="project.status == 'pending' || project.status == 'in-progress'" class="card text-center mt-5">
                <div class="card-body pt-5 pb-5">
                  Votre projet est en cours de traitement
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="1">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 1}"
              [style.background-color]="sectionId == 1 ? settings.primary_color : ''" (click)="selectTab( 1 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 1 && light == true ? 'black' : ''"><i
                  class="fas fa-camera"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 1 && light == true ? 'black' : ''">Visuels
                3D</span>
            </a>
            <ng-template ngbNavContent>
              <p class="text-center title-chat d-block d-sm-none" [style.color]="light == true ? 'black' : 'white'"
              [style.background-color]="settings.primary_color ? settings.primary_color : ''">Visuels 3D
              </p>
              <ngb-carousel [interval]="0" *ngIf="project.status == 'completed' && project.photos.length  > 0">
                <ng-template ngbSlide *ngFor="let photo of project.photos">
                  <div class="photo">
                    <img [src]="photo.url" alt="Random first slide" />
                  </div>
                </ng-template>
              </ngb-carousel>
              <div *ngIf="project.status == 'pending' || project.status == 'in-progress'" class="card text-center mt-5">
                <div class="card-body pt-5 pb-5">
                  Votre projet est en cours de traitement
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 2}"
              [style.background-color]="sectionId == 2 ? settings.primary_color : ''" (click)="selectTab( 2 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 2 && light == true ? 'black' : ''"><i
                  class="fas fa-video"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 2 && light == true ? 'black' : ''">Visite
                360°</span>
            </a>
            <ng-template ngbNavContent>
              <p class="text-center title-chat d-block d-sm-none" [style.color]="light == true ? 'black' : 'white'"
              [style.background-color]="settings.primary_color ? settings.primary_color : ''">Visite 360°
              </p>
              <div class="card" *ngIf="project.status == 'completed'">
                <iframe id="i_frame" about="_blank" [src]='visitURL' width="100%" height="750px" title="Rendu 3D">
                </iframe>
              </div>
              <div *ngIf="project.status == 'pending' || project.status == 'in-progress'" class="card text-center mt-5">
                <div class="card-body pt-5 pb-5">
                  Votre projet est en cours de traitement
                </div>
              </div>
            </ng-template>
          </li>

          <li [ngbNavItem]="3">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 3}"
              [style.background-color]="sectionId == 3 ? settings.primary_color : ''" (click)="selectTab( 3 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 3 && light == true ? 'black' : ''"><i
                  class="fas fa-shopping-cart"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 3 && light == true ? 'black' : ''">Shopping
                list</span>
            </a>
            <ng-template ngbNavContent>
              <p class="text-center title-chat d-block d-sm-none" [style.color]="light == true ? 'black' : 'white'"
              [style.background-color]="settings.primary_color ? settings.primary_color : ''">Shopping list
              </p>
              <div class="card pt-10"
                *ngIf="project.status == 'completed' && project.products.length  > 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3 col-sm-12" *ngFor="let product of project.products">
                      <div class="produit">

                        <div class="alert">
                          <div>
                            <i class="fa fa-check-circle"></i> <br>
                            Produit ajouté
                          </div>
                        </div>

                        <div class="heading">

                          <div class="img">
                            <img src="{{ product.photo_uri }}" alt="">
                            <a href="{{product.link}}" target="_blank" class="savoir-plus">En savoir plus</a>
                          </div>
                        </div>
                        <div class="foot">
                          <div class="info">
                            <b>{{product.name}}</b>
                            <p>{{product.reference}}</p>
                            <p class="dimensions font-size-11 mt-2">
                              {{product.largeur ? 'L.' +product.largeur :''}}
                              {{product.largeur && (product.hauteur || product.profondeur) ? 'x ':'' }}
                              {{product.hauteur ? 'H.' +product.hauteur :''}}
                              {{product.hauteur && product.profondeur ? 'x ':'' }}
                              {{product.profondeur ? 'P.' +product.profondeur:''}}
                            </p>
                          </div>
                          <p class="prix_public font-weight-bold mt-4 mb-0">Prix public {{ product.price }} €</p>
                        </div>
                        <div class="hover">
                          <a *ngIf="product.link" href="{{product.link}}" target="_blank"
                            class="btn-voir-produit  btn-formulaire">Voir le produit</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div *ngIf="project.status == 'pending' || project.status == 'in-progress'" class="card text-center mt-5">
                <div class="card-body pt-5 pb-5">
                  Votre projet est en cours de traitement
                </div>
              </div>
            </ng-template>
          </li>

        </ul>
        <div class="pb-5" [ngbNavOutlet]="justifiednav"></div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="row chat-row">
          <div class="col-md-12">
            <p class="text-center title-chat" [style.color]="light == true ? 'black' : 'white'"
              [style.background-color]="settings.primary_color ? settings.primary_color : ''">Discuter avec votre
              designer
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-chat *ngIf="designer" [sender]="user" [receiver]="designer" [project]="project"></app-chat>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
