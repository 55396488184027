<div class="container pt-3" *ngIf="user && user.type === 'user'">
  <div class="row jet-font">
    <div class="col-6 col-md-6 col-sm-8">
      <a class="btn btn-home" href="./">
        <span class="d-block d-sm-none" ><i class="fas fa-home"></i></span>
        <span class="d-none d-sm-block" >Accueil<i class="fas fa-home"></i></span>
      </a>

    </div>
    <div class="col col-md-6 col-sm-2 text-right">
      <!-- <div (click)="logout()" class="logout">Déconnexion</div> -->
      <div (click)="logout()" class="btn btn-logout">
        <span class="d-block d-sm-none" ><i class="fas fa-sign-out-alt"></i></span>
        <span class="d-none d-sm-block" ><i class="fas fa-sign-out-alt"></i>Déconnexion</span>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h6 class="mb-4 jet-font">Profil</h6>
          <div class="card">
            <div class="card-header row jet-font" (click)="isCollapsedInfo = !isCollapsedInfo">
              <div class="col-md-9 text-left">
                Informations générales
              </div>
              <div class="col-md-3 text-right">
                <i class="fas fa-sort-down" *ngIf="isCollapsedInfo"></i>
                <i class="fas fa-sort-up" *ngIf="!isCollapsedInfo"></i>
              </div>
            </div>
            <div class="card-body collapse" [ngbCollapse]="isCollapsedInfo">
              <form class="needs-validation" [formGroup]="formInfo" (ngSubmit)="postInfo()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Prénom</b></label>
                      <input type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{'is-invalid': submitInfo && fInfo.firstName.errors}" />
                      <div *ngIf="submitInfo && fInfo.firstName.errors" class="invalid-feedback">
                        <span *ngIf="fInfo.firstName.errors.required">Champ obligatoire.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Nom</b></label>
                      <input type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{'is-invalid': submitInfo && fInfo.lastName.errors}" />
                      <div *ngIf="submitInfo && fInfo.lastName.errors" class="invalid-feedback">
                        <span *ngIf="fInfo.lastName.errors.required">Champ obligatoire.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Email</b></label>
                      <input type="email" class="form-control" formControlName="email"
                        [ngClass]="{'is-invalid': submitInfo && fInfo.email.errors}" />
                      <div *ngIf="submitInfo && fInfo.email.errors" class="invalid-feedback">
                        <span *ngIf="fInfo.email.errors.required">Champ obligatoire.</span>
                        <span *ngIf="fInfo.email.errors.pattern">Email non valide.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-primary jet-font" type="submit">
                    <i class="far fa-address-card"></i>
                    {{isActiveInfo? 'Enregistrer':'Modifier'}}</button>
                </div>
              </form>
            </div>
          </div>
          <div class="card">
            <div class="card-header row jet-font" (click)="isCollapsedPwd = !isCollapsedPwd">
              <div class="col-md-9 text-left">
                Sécurité
              </div>
              <div class="col-md-3 text-right">
                <i class="fas fa-sort-down" *ngIf="isCollapsedPwd"></i>
                <i class="fas fa-sort-up" *ngIf="!isCollapsedPwd"></i>
              </div>
            </div>
            <div class="card-body collapse" [ngbCollapse]="isCollapsedPwd">
              <form class="needs-validation" [formGroup]="formPwd" (ngSubmit)="postPwd()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Ancien mot de passe</b></label>
                      <input type="password" class="form-control" placeholder="" formControlName="oldpassword"
                        [ngClass]="{'is-invalid': submitFC && fC.oldpassword.errors}" />
                      <div *ngIf="submitFC && fC.oldpassword.errors" class="invalid-feedback">
                        <span *ngIf="fC.oldpassword.errors.required">Champ obligatoire.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Nouveau mot de passe</b></label>
                      <input type="password" class="form-control" placeholder=" " formControlName="password"
                        [ngClass]="{'is-invalid': submitFC && fC.password.errors}" />
                      <div *ngIf="submitFC && fC.password.errors" class="invalid-feedback">
                        <span *ngIf="fC.password.errors.required">Champ obligatoire.</span>
                        <span *ngIf="fC.password.errors.minlength">Le mot de passe doit être au moins 6 caractères.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="card-title"><b>Confirmation du mot de passe</b></label>
                      <input type="password" class="form-control" placeholder="" formControlName="confirmpwd"
                        [ngClass]="{'is-invalid': submitFC && fC.confirmpwd.errors}" />
                      <div *ngIf="submitFC && fC.confirmpwd.errors" class="invalid-feedback">
                        <span *ngIf="fC.confirmpwd.errors.required">Champ obligatoire.</span>
                        <span *ngIf="fC.confirmpwd.errors.mustMatch">This value should be the same.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-primary jet-font" type="submit">
                    <i class="fas fa-unlock-alt"></i>
                    {{isActivePwd? 'Enregistrer':'Changer mot de passe'}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
