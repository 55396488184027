import { Component, OnInit } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  projects: any[];
  term: string;
  listDesigners: [];
  selected: number;
  project_id: number;
  constructor(
    public ws: WS,
    private modalService: NgbModal
  ) {
  }
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Projects', active: true }];
    this.getProjects();
    this.getDesigners();

  }

  getProjects() {
    this.ws.get('projects')
      .subscribe(
        data => {
          this.projects = data;
        }
      );
  }
  getDesigners(): void {
    this.ws.get('designers')
      .subscribe(
        data => {
          this.listDesigners = data;
        }
      );
  }
  modal(Data: any, id: number) {
    this.modalService.open(Data, { centered: true, size: 'md' });
    this.project_id = id;
  }

  updateDesigner() {
    this.ws.put('projects/update-designer/' + this.project_id, { 'designer_id': this.selected })
      .subscribe(
        data => {
          // this.toastr.success('Mise à jour avec succès');
          this.modalService.dismissAll();
          this.getProjects();
          Swal.fire({
            title: 'Designer sélectionné',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
        }
      )
  }

  getStatusName(status): string {
    return {
      'new': 'Nouveau',
      'draft': 'Brouillon',
      'sended': 'Envoyé',
      'in-progress': 'En cours',
      'pending': 'Vérification',
      'completed': 'Terminé'
    }[status];
  }
  deleteProject(id): void {
    Swal.fire({
      title: 'Supprimer ce projet ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.ws.delete('projects/' + id)
          .subscribe(
            data => {
              this.getProjects();
            }
          )
      }
    })
  }

}
