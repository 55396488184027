<div class="container-fluid" *ngIf="project">
  <app-pagetitle title="Projet : {{project.reference }} - {{ project.created_at | date}} - {{project.title}}">
  </app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto">
              <h5 class="font-size-13 ">Statut du projet : </h5>
            </div>
            <div class="col">
              <h5 class="font-size-13 ">{{getStatusName(project.status)}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <h5 class="font-size-13 ">Utilisateur : </h5>
            </div>
            <div class="col">
              <h5 class="font-size-13 ">{{project.customer.fullname}}</h5>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <ul ngbNav #justifiednav2="ngbNav" class="nav-pills">
                <li [ngbNavItem]="1">
                  <a ngbNavLink (click)="selectHeader( 0 )">
                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                    <span class="d-none d-sm-block">Questionnaire</span>
                  </a>
                  <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="TabQuestionnaire"></ng-template>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink (click)="selectHeader( 1 )">
                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                    <span class="d-none d-sm-block">Restitution</span>
                  </a>
                  <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="TabRestitution"></ng-template>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="justifiednav2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Tab Content -->
<ng-template #TabQuestionnaire>

  <div class="p-3 mb-0 sections-container">

    <div class="row mb-5">
      <div class="col-md-12 text-center">
        <div *ngFor="let section of project.quiz.sections; let index = index" class="section-title-div">
          <h3 class="section-title" [class]="showSections[index] ? 'active':'' "
            (click)="showSections = []; showSections[index] = true">
            {{ section.name }}
          </h3>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="sections-content" *ngFor="let section of project.quiz.sections; let index = index"
          [class]="showSections[index] ? 'active':'' ">
          <div *ngFor="let question of section.questions">
            <h5 [class]="question.type" class="font-italic text-justify">{{ question.title }}</h5>

            <container-element *ngIf="getResponse(question.id)" [ngSwitch]="question.type">

              <div class="slider" *ngSwitchCase="'slider'">
                <div *ngIf="getResponse(question.id).response; then sliderTouched else sliderNotTouched"></div>
                <ng-template #sliderTouched>
                  <div class="col-md-6">
                    <ng5-slider [(value)]="getResponse(question.id).response.value"
                      [(highValue)]="getResponse(question.id).response.highValue"
                      [options]="{floor: question.options.min,ceil: question.options.max, readOnly:true}">
                    </ng5-slider>
                  </div>
                </ng-template>

                <ng-template #sliderNotTouched>
                  <div class="col-md-6">
                    <div style="opacity: 0.4">
                      <ng5-slider [(value)]="question.options.min" [(highValue)]="question.options.max"
                        [options]="{floor: question.options.min,ceil: question.options.max, readOnly:true}">
                      </ng5-slider>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div class="textarea" *ngSwitchCase="'textarea'">
                <b class="text-success" style="white-space: pre-line;">{{ getResponse(question.id).response }}</b>
              </div>

              <div class="photos" *ngSwitchCase="'photos'">

                <div class="img" *ngFor="let photo of getResponse(question.id).responses"
                  [ngClass]="{ 'video' : question.options.accept_video }">

                  <div *ngIf="getMediaExtension(photo.url) == 'pdf'">
                    <a href="{{ photo.url }}" target="_blank">
                      <img src="assets/images/pdf.png" alt="pdf file">
                    </a>
                    <p class="comment-card" *ngIf="photo.comment">{{ photo.comment }}</p>
                  </div>
                  <div *ngIf="getMediaExtension(photo.url) != 'pdf'">
                    <div *ngIf="question.options.accept_video">
                      <video src="{{ photo.url }}" controls="" width="300" height="200"></video>
                      <p class="comment-card" *ngIf="photo.comment">{{ photo.comment }}</p>
                    </div>


                    <a *ngIf="question.options.accept_photo" href="{{ photo.url }}" target="_blank">
                      <img [src]="photo.url" alt="{{ photo.url }}">
                    </a>
                    <p class="comment-card" *ngIf="photo.comment">{{ photo.comment }}</p>
                  </div>


                </div>
              </div>

              <div class="video" *ngSwitchCase="'video'">video</div>

              <div class="links" *ngSwitchCase="'links'">
                <div class="link" *ngFor="let item of  getResponse(question.id).response">
                  <a [href]="item" target="_blank" class="d-block mb-2 text-success">{{ item }}</a>
                  <!-- <p class="comment-card" *ngIf="item && item.comment">{{ item.comment }}</p> -->
                </div>
              </div>

              <div class="rank" *ngSwitchCase="'rank'">
                <div class="d-block mt-2 mb-2 text-success font-weight-bold">{{ getResponse(question.id).response}}
                </div>
              </div>

              <div class="checkbox" *ngSwitchCase="'checkbox'">

                <div class="item" [class]="item.value ? 'true':'false'"
                  *ngFor="let item of  getResponse(question.id).response  | keyvalue">
                  {{ item.key }}
                </div>


              </div>

              <div class="radio checkbox" *ngSwitchCase="'radio'">
                <div class="item" [class]="item.value ? 'true':'false'"
                  *ngFor="let item of  getResponse(question.id).response  | keyvalue">
                  {{ item.key }}
                </div>
              </div>
              <div *ngSwitchDefault></div>

            </container-element>
            <hr>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-template>

<!-- Tab Content -->
<ng-template #TabRestitution>

  <div class="row mt-2 p-4">
    <div class="col">
      <div>
        <ul ngbNav #justifiednav="ngbNav" [activeId]="sectionId" class="nav-pills nav-justified mb-4">
          <li [ngbNavItem]="0">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 0}"
              [style.background-color]="sectionId == 0 ? settings.primary_color : ''" (click)="selectTab( 0 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 0 && light == true ? 'black' : ''"><i
                  class="fas fa-camera"></i></span>
              <span class="d-none d-sm-block"
                [style.color]="sectionId == 0 && light == true ? 'black' : ''">Brief</span>
            </a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body" style="white-space: pre-wrap;">
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea name="description" [disabled]="isDescDisabled"
                        [(ngModel)]="project.response_description" rows="10" class="form-control"
                        placeholder="Entrez la description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-5 mb-4">
                <button class="btn btn-primary jet-font" (click)="saveDescription()">
                  {{isDescDisabled ?'Modifier':'Enregistrer'}}</button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="1">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 1}"
              [style.background-color]="sectionId == 1 ? settings.primary_color : ''" (click)="selectTab( 1 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 1 && light == true ? 'black' : ''"><i
                  class="fas fa-camera"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 1 && light == true ? 'black' : ''">Visuels
                3D</span>
            </a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="row m-auto pt-5">
                  <h5 style="font-family: poppins;">Insérer les visuels et le plan</h5>
                </div>
                <div class="row m-auto">
                  <span class="font-size-13 mt-1 text-danger">Format recommandé : paysage largeur / hauteur =
                    <strong>1.8</strong> (Exemple : 850px x 472px ou 3308px x 1838px) </span>
                </div>
                <div class="row pt-5" *ngIf="project.photos.length > 0">
                  <div class="col-md-1">
                  </div>
                  <div class="col-md-10">
                    <ngb-carousel [interval]="0" *ngIf="project.photos.length > 0">
                      <ng-template ngbSlide *ngFor="let photo of project.photos">
                        <div class="photo">
                          <div class="photo-chooser mb-4">
                            <div class="btn btn-rounded btn-warning edit">
                              <i class="fas fa-edit font-size-18"></i>
                              <input type="file" name="photo_update" (change)="updateFileProgress($event , photo.id)">
                            </div>
                            <div class="btn btn-rounded btn-danger delete" (click)="deletePhoto(photo.id)">
                              <i class="fas fa-trash-alt font-size-18"></i>
                            </div>
                            <img [src]="photo.url">
                          </div>
                        </div>
                      </ng-template>
                    </ngb-carousel>
                  </div>
                  <div class="col-md-1">
                  </div>
                </div>
                <div class="row pt-5 pb-5">
                  <div class="col-md-1">
                  </div>
                  <div class="col-md-10">
                    <div class="photo-chooser-input">
                      <img *ngIf="previewUrl" [src]="previewUrl" />
                      <i class="fa fa-camera" *ngIf="!previewUrl"></i>
                      <input type="file" name="photo" (change)="fileProgress($event)">
                    </div>
                  </div>
                  <div class="col-md-1">

                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 2}"
              [style.background-color]="sectionId == 2 ? settings.primary_color : ''" (click)="selectTab( 2 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 2 && light == true ? 'black' : ''"><i
                  class="fas fa-video"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 2 && light == true ? 'black' : ''">Visite
                360°</span>
            </a>
            <ng-template ngbNavContent>
              <div class="card" *ngIf="visitURL">
                <div class="card-body">
                  <iframe id="i_frame" about="_blank" [src]='visitURL' width="100%" height="750px" title="Rendu 3D">
                  </iframe>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <input type="text" name="visit" [disabled]="isVisitDisabled" [(ngModel)]="project.visit"
                    class="form-control" placeholder="Copier le lien du 360">
                </div>
              </div>
              <div class="text-center mt-5 mb-4">
                <button class="btn btn-primary jet-font" (click)="saveVisit()">
                  {{isVisitDisabled ?'Modifier':'Enregistrer'}}</button>
              </div>


            </ng-template>
          </li>

          <li [ngbNavItem]="3">
            <a ngbNavLink class="mb-2" [class]="{'selected':sectionId == 3}"
              [style.background-color]="sectionId == 3 ? settings.primary_color : ''" (click)="selectTab( 3 )">
              <span class="d-block d-sm-none" [style.color]="sectionId == 3 && light == true ? 'black' : ''"><i
                  class="fas fa-shopping-cart"></i></span>
              <span class="d-none d-sm-block" [style.color]="sectionId == 3 && light == true ? 'black' : ''">Shopping
                list</span>
            </a>
            <ng-template ngbNavContent>
              <div class="card" style="padding: 10px;" *ngIf="project.products.length == 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">

                    </div>
                    <div class="col-md-4">
                      <div class="text-right mb-4">
                        <button (click)="modal(addProduct)" class="btn btn-info">Ajouter un nouveau produit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" style="padding: 10px;" *ngIf="project.products.length > 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">

                    </div>
                    <div class="col-md-4">
                      <div class="text-right mb-4">
                        <button (click)="modal(addProduct)" class="btn btn-info">Ajouter un nouveau produit</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3" *ngFor="let product of project.products">
                      <div class="produit">

                        <div class="alert">
                          <div>
                            <i class="fa fa-check-circle"></i> <br>
                            Produit ajouté
                          </div>
                        </div>

                        <div class="heading">

                          <div class="img">
                            <img src="{{ product.photo_uri }}" alt="">
                            <a href="{{product.link}}" target="_blank" class="savoir-plus">En savoir plus</a>
                          </div>
                        </div>
                        <div class="foot">
                          <div class="info">
                            <b>{{product.name}}</b>
                            <p>{{product.reference}}</p>
                            <p class="dimensions font-size-11 mt-2">
                              {{product.largeur ? 'L.' +product.largeur :''}}
                              {{product.largeur && (product.hauteur || product.profondeur) ? 'x ':'' }}
                              {{product.hauteur ? 'H.' +product.hauteur :''}}
                              {{product.hauteur && product.profondeur ? 'x ':'' }}
                              {{product.profondeur ? 'P.' +product.profondeur:''}}
                            </p>
                          </div>
                          <p class="prix_public font-weight-bold mt-4 mb-0">Prix public {{ product.price }} €</p>
                        </div>
                        <div class="hover">
                          <div class="btn btn-rounded edit-product" style="color:#fcb92c;" (click)="editProduct(product.id)">
                            <i class="fas fa-edit font-size-18"></i>
                          </div>
                          <div class="btn btn-rounded delete-product" style="color: red;" (click)="deleteProduct(product.id)">
                            <i class="fas fa-trash-alt font-size-18"></i>
                          </div>
                          <a *ngIf="product.link" href="{{product.link}}" target="_blank"
                            class="btn-voir-produit  btn-formulaire">Voir le produit</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div class="pb-5" [ngbNavOutlet]="justifiednav"></div>
      </div>
    </div>
    <div class="col-3">
      <div class="row text-center validation-row">
        <div class="text-center m-auto pb-25" *ngIf="project.status === 'in-progress'">
          <button class="btn btn-primary jet-font" (click)="verifyProject()" [disabled]="!valid">
            <i class="fas fa-paper-plane"></i>
            Demander validation</button>
        </div>
        <div class="text-center m-auto pb-25" *ngIf="project.status === 'pending'">
          <button class="btn btn-primary jet-font" (click)="updateVerifyProject()" [disabled]="!valid">
            <i class="fas fa-paper-plane"></i>
            Demander validation</button>
        </div>
      </div>
      <div class="row chat-row">
        <div class="col-md-12">
          <p class="text-center title-chat" [style.color]="light == true ? 'black' : 'white'"
            [style.background-color]="settings.primary_color ? settings.primary_color : ''">Discuter avec votre
            client
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-chat [sender]="user" [receiver]="project.customer" [project]="project"></app-chat>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addProduct let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Ajouter un produit</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" (ngSubmit)="post()" [formGroup]="form">
      <div class="row">
        <div class="col-md-6">
          <label>Nom *</label>
          <div class="form-group">
            <input type="text" class="form-control" formControlName="name"
              [ngClass]="{'is-invalid': submit && f.name.errors}" placeholder=" " />
            <div *ngIf="submit && f.name.errors" class="invalid-feedback">
              <span *ngIf="f.name.errors.required">Champ obligatoire.</span>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <label>Marque *</label>
          <div class="form-group">
            <input type="text" class="form-control" formControlName="marque"
              [ngClass]="{'is-invalid': submit && f.marque.errors}" placeholder=" " />
            <div *ngIf="submit && f.marque.errors" class="invalid-feedback">
              <span *ngIf="f.marque.errors.required">Champ obligatoire.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Référence</label>
          <div class="form-group">
            <input type="text" class="form-control" formControlName="reference"
              [ngClass]="{'is-invalid': submit && f.reference.errors}" placeholder=" " />
          </div>
        </div>
        <div class="col-md-6">
          <label>Photo *</label>
          <div *ngIf="previewUrlProduct">
            <img *ngIf="previewUrlProduct" style="height: 5rem;" [src]="previewUrlProduct" class="rounded-circle mb-2"
              alt="photo-produit" />
          </div>
          <div class="custom-file form-group">
            <input type="file" class="form-control" formControlName="photo" (change)="fileProgressProduct($event)"
              class="custom-file-input" id="customFile" [ngClass]="{'is-invalid': submit && f.photo.errors}">
            <label class="custom-file-label" for="customFile">Choisir un fichier</label>
            <div *ngIf="submit && f.photo.errors" class="invalid-feedback">
              <span *ngIf="f.photo.errors.required">Champ obligatoire.</span>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Lien *</label>
          <div class="form-group">
            <input type="url" class="form-control" formControlName="link"
              [ngClass]="{'is-invalid': submit && f.link.errors}" placeholder=" " />
            <div *ngIf="submit && f.link.errors" class="invalid-feedback">
              <span *ngIf="f.link.errors.required">Champ obligatoire.</span>
              <span *ngIf="f.link.errors.pattern">URL non valide.</span>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Prix *</label>
          <div class="form-group">
            <div class="input-group">
              <input type="number" formControlName="price" class="form-control"
                [ngClass]="{'is-invalid': submit && f.price.errors}">
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
              <div *ngIf="submit && f.price.errors" class="invalid-feedback">
                <span *ngIf="f.price.errors.required">Champ obligatoire.</span>
                <span *ngIf="f.price.errors.pattern">Numéro non valide.</span>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-3">
          <label>Hauteur</label>
          <div class="form-group">
            <input type="number" class="form-control" formControlName="hauteur"
              [ngClass]="{'is-invalid': submit && f.hauteur.errors}" placeholder=" " />
            <div *ngIf="submit && f.hauteur.errors" class="invalid-feedback">
              <span *ngIf="f.hauteur.errors.pattern">Numéro non valide.</span>
            </div>

          </div>
        </div>
        <div class="col-md-3">
          <label>Largeur</label>
          <div class="form-group">
            <input type="number" class="form-control" formControlName="largeur"
              [ngClass]="{'is-invalid': submit && f.largeur.errors}" placeholder=" " />
            <div *ngIf="submit && f.largeur.errors" class="invalid-feedback">
              <span *ngIf="f.largeur.errors.pattern">Numéro non valide.</span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label>Profondeur</label>
          <div class="form-group">
            <input type="number" class="form-control" formControlName="profondeur"
              [ngClass]="{'is-invalid': submit && f.profondeur.errors}" placeholder=" " />
            <div *ngIf="submit && f.profondeur.errors" class="invalid-feedback">
              <span *ngIf="f.profondeur.errors.pattern">Numéro non valide.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-2">
        <button class="btn btn-primary " type="submit">Ajouter</button>
      </div>

    </form>
  </div>
</ng-template>
