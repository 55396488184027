<div class="container-fluid" *ngIf="user">
  <app-pagetitle title="Utilisateur : {{ user.fullname }}"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <div class="col-lg-4">
            <div class="ps-content">
              <ul class="list-unstyled activity-wid">
                <li class="activity-list pb-3" *ngIf="user.email">
                  <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-at-fill"></i>
                    </span>
                  </div>
                  <h5 class="font-size-13 ">Email</h5>
                  <p class="text-muted mb-0">{{user.email}}</p>
                </li>
                <li class="activity-list pb-3" *ngIf="user.phone">
                  <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-phone-fill"></i>
                    </span>
                  </div>
                  <h5 class="font-size-13">Téléphone</h5>
                  <p class="text-muted mb-0">{{user.phone}}</p>
                </li>
                <li class="activity-list pb-3" *ngIf="user.address">
                  <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-map-pin-fill"></i>
                    </span>
                  </div>
                  <h5 class="font-size-13">Adresse</h5>
                  <p class="text-muted mb-0">{{user.address}}</p>
                </li>
                <li class="activity-list pb-3" *ngIf="user.city">
                  <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-map-fill"></i>
                    </span>
                  </div>
                  <h5 class="font-size-13">Ville</h5>
                  <p class="text-muted mb-0">{{user.city}}</p>
                </li>
                <li class="activity-list pb-3" *ngIf="user.postal_code">
                  <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-mail-fill"></i>
                    </span>
                  </div>
                  <h5 class="font-size-13">Code postal</h5>
                  <p class="text-muted mb-0">{{user.postal_code}}</p>
                </li>
              </ul>
            </div>




          </div>


          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered">
              <thead class="thead-light">
                <tr>
                  <th>Reférence</th>
                  <th>Date de création</th>
                  <th>Objet</th>
                  <th>Produits suggérés</th>
                  <th>Statut</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of user.projects|filter:term">
                  <td>{{item.reference}}</td>
                  <td>{{item.created_at | date}}</td>
                  <td>{{item.title}}</td>
                  <td>{{ item.products_count }}</td>
                  <td>
                    <div class="badge font-size-12" [ngClass]="{
                          'badge-soft-primary': item.status === 'new',
                          'badge-soft-success':item.status === 'completed',
                          'badge-soft-warning': item.status === 'in-progress' || item.status === 'pending'}">
                          {{getStatusName(item.status)}}
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
