import { Component, OnInit } from '@angular/core';

import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projects: any[];
  term: string;
  user: any;

  constructor(
    public ws: WS,
    private authFackservice: AuthfakeauthenticationService
  ) {
  }
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Projects', active: true }];

    this.user = this.authFackservice.currentUserValue;
  }
}
