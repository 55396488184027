import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import Pusher from 'pusher-js';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {
	@Input() sender;
	@Input() receiver;
	@Input() project;
	@Input() disabled;

	@ViewChild('scrollMe')
	private myScrollContainer: ElementRef;

	chatMessagesData = [];
	message;

	constructor(
		public ws: WS,
		public authFackservice: AuthfakeauthenticationService,
	) { }

	ngAfterViewChecked() {
		this.scrollToBottom();
	}

	scrollToBottom(): void {
		try {
			this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
		} catch (err) { }
	}

	ngOnInit(): void {
		const pusher = new Pusher('e25d7fcf8ab9f2d20889', { cluster: 'eu' });
		const channel = pusher.subscribe('chat' + this.project.id);
		channel.bind('message', data => {
			this.chatMessagesData.push(data.message);
			this.scrollToBottom();
		})
		this.chatMessagesData = this.project.messages;
		this.scrollToBottom();
	}

	send() {
		if (!this.message.trim()) {
			return;
		}
		this.ws.post('chats/send', {
			project_id: this.project.id,
			user_id: this.sender.id,
			message: this.message
		})
			.subscribe(
				data => {
					this.scrollToBottom();
					this.message = '';
				})

	}

}
