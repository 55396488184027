<div class="container-fluid">

  <app-pagetitle title="Projets"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-right pt-2" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle
              data-toggle="dropdown" aria-expanded="false">
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a href="javascript:void(0);" class="dropdown-item">Exporter</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered">
              <thead class="thead-light">
                <tr>
                  <th>Reférence</th>
                  <th>Date de création</th>
                  <th>Client</th>
                  <th>Architecte</th>
                  <th>Objet</th>
                  <th>Nombre de produits</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of projects|filter:term">
                  <td>
                    <a *ngIf="item.status != 'new';else other_content" [routerLink]="['admin/', item.id]">{{item.reference}}</a>
                    <ng-template #other_content>{{item.reference}}</ng-template>
                  </td>
                  <td>{{item.created_at | date}}</td>
                  <td>{{item.customer? item.customer.fullname : '---'}}</td>
                  <td>
                    <span (click)="modal(domainNameDataModal,item.id)" *ngIf="item.status != 'new'">
                      <i class="fa fa-pen"></i>
                      {{ item.designer? item.designer.fullname : '--'}}
                    </span>
                  </td>
                  <td>{{item.title}}</td>
                  <td>{{item.products.length}}</td>
                  <td>
                    <div class="badge font-size-12" [ngClass]="{
                          'badge-soft-primary': item.status === 'new',
                          'badge-soft-info': item.status === 'sended',
                          'badge-soft-success':item.status === 'completed',
                          'badge-soft-danger': item.status === 'pending',
                          'badge-soft-warning': item.status === 'in-progress'}">
                      {{getStatusName(item.status)}}
                    </div>
                  </td>
                  <td class="text-center">
                    <div *ngIf="item.status == 'new'" (click)="deleteProject( item.id )" class="btn btn-sm btn-rounded btn-danger"> <i class="fa fa-trash"></i></div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #domainNameDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Sélectionner designer</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <ng-select [items]="listDesigners" bindValue="id" bindLabel="firstname" [(ngModel)]="selected"></ng-select>
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary" (click)="updateDesigner()">Valider</button>

      </div>
    </div>
  </div>
</ng-template>
