<div class="container-fluid">
  <app-pagetitle title="Options d'intégration"></app-pagetitle>

  <div class="card">
    <div class="card-body">

      <div class="m-5">
        <!-- qst1 -->
        <h4 class="card-title btn-group-sm">1. Vous intégrez un script dans votre site
          <div class="btn btn-primary btn-circle" (click)="modal(scriptDataModal)"><i class="fas p-0 fa-info "></i>
          </div>
        </h4>
        <div class="row mb-5">
          <div class="col-md-8 ">
            <div class="form-group">
              <textarea rows="5" readonly class="form-control">{{ iframeScript }}</textarea>
            </div>
          </div>
          <div class="col-md-4">
            <button class="btn btn-primary jet-font" type="submit"
              (click)="copyToClipboard( iframeScript )">Copier</button>
          </div>
        </div>


        <!-- qst2 -->
        <form class="needs-validation" [formGroup]="form" (ngSubmit)="validSubmit()">
          <h4 class="card-title btn-group-sm">2. Vous créez un domaine
            <div (click)="modal(domainNameDataModal)" class="btn btn-primary btn-circle"><i
                class="fas p-0 fa-info "></i>
            </div>
          </h4>
          <div class="row mb-5">
            <div class="col-md-8 ">
              <div class="form-group">
                <input type="text" class="form-control" name="domaine_name" [(ngModel)]="this.settings.domaine_name"
                  [ngModelOptions]="{standalone: true}" placeholder="Enterz le nom de domaine dédié" />
              </div>
            </div>
            <div class="col-md-4">
              <button class="btn btn-primary jet-font" type="submit">Enregistrer</button>
            </div>
          </div>


          <!-- qst3 -->
          <h4 class="card-title btn-group-sm">3. Nous créons un sous domaine pour vous
            <div class="btn btn-primary btn-circle" (click)="modal(subDomainDataModal)"><i class="fas p-0 fa-info "></i>
            </div>
          </h4>
          <div class="row mb-5">
            <div class="col-md-8 ">
              <div class="form-group">
                <input type="text" class="form-control" name="sub_domain" [(ngModel)]="this.settings.sub_domain"
                  [ngModelOptions]="{standalone: true}" placeholder="[client].coachingdeco.fr" />
                {{ this.settings.sub_domain || '[client]' }}.coachingdeco.fr
              </div>
            </div>
            <div class="col-md-4">
              <button class="btn btn-primary jet-font">Enregistrer</button>
            </div>
          </div>
        </form>
      </div>

      <!-- <div class="text-right">
        <button class="btn btn-primary jet-font" (click)="modal(contact)">
          <i class="fa-solid fa-clipboard-question"></i>
          Demander une assistance</button>
      </div> -->


    </div>
  </div>
  <!-- end card -->
</div>

<ng-template #domainNameDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Intégration avec un domaine</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <p>Contactez-nous pour plus d'information</p>
  </div>
</ng-template>
<ng-template #scriptDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Intégration avec un script</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <!-- <p>&lt;div class="CollectionMain"&gt;</p>
    <p>&lt;section style="padding: 50px"&gt;</p>
    <p>&lt;iframe id="i_frame" about="_blank" src="https://deconome.pipcke.fr/apercu" width="100%" height="2000px"
      title="Pipcke"&gt;&lt;/iframe&gt;</p>
    <p>&lt;/section&gt;</p>
    <p>&lt;/div&gt;</p> -->
    <p>1- Copier le code HTML &lt;iframe&gt; de votre espace de coaching déco.</p>
    <p>2- Préparer une page dédiée sur votre site pour intégrer le module de coaching déco.</p>
    <p>3- Ajouter le code HTML &lt;iframe&gt; dans la page dédiée.</p>
  </div>
</ng-template>
<ng-template #subDomainDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Intégration avec un sous domaine</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <p>Contactez-nous pour plus d'information</p>
  </div>
</ng-template>

<ng-template #contact let-modal>
  <div class="modal-header ">
    <h5 class="modal-title mt-0">Contacter le support</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <app-integrations-contact></app-integrations-contact>
  </div>
</ng-template>
