import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';

import { PagesRoutingModule } from './pages-routing.module';
import { Ng5SliderModule } from 'ng5-slider';
import { NgbNavModule, NgbDropdownModule, NgbTooltipModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';

import { UsersComponent } from './users/users.component';
import { DetailsComponent } from './users/details/details.component';

import { ColorPickerModule } from 'ngx-color-picker';

import { ProjectsComponent } from './projects/projects.component';
import { ClientComponent } from './projects/client/index/client.component';
import { DetailsProjectComponent } from './projects/client/details/details.component';
import { ArchitectComponent } from './projects/architect/index/architect.component';
import { DetailsArchitectProjectComponent } from './projects/architect/details/details.component';
import { AdminComponent } from './projects/admin/index/admin.component';
import { DetailsProjectAdminComponent } from './projects/admin/details/details.component';
import { DetailsProjectUserComponent } from './projects/user/details/details.component';

import { IntegrationsComponent } from './integrations/integrations.component';
import { CompteComponent } from './compte/compte.component';
import { QuizComponent } from './quiz/quiz.component';
import { DetailsQuizComponent } from './quiz/details/details.component';
import { TitleComponent } from './quiz/questions/title/title.component';
import { QcmComponent } from './quiz/questions/qcm/qcm.component';
import { RangesliderComponent } from './quiz/questions/rangeslider/rangeslider.component';
import { TextareaComponent } from './quiz/questions/textarea/textarea.component';
import { FilesComponent } from './quiz/questions/files/files.component';
import { ContactComponent } from './integrations/contact/contact.component';
import { DesignersComponent } from './designers/designers.component';
import { LinksComponent } from './quiz/questions/links/links.component';
import { RankComponent } from './quiz/questions/rank/rank.component';
import { ChatComponent } from './components/chat/chat.component';
import { PaiementComponent } from './paiement/paiement.component';
import { QuizColorsConfiguratorComponent } from './components/quiz-colors-configurator/quiz-colors-configurator.component';
import { NgbCarouselModule ,NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CouponsComponent } from './coupons/coupons.component';
import { ProfileComponent } from './profile/profile.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { FailurePaymentComponent } from './failure-payment/failure-payment.component';
import { CopyQuizComponent } from './quiz/copy/copy-quiz/copy-quiz.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: false,
  // suppressScrollX: true,
  // wheelSpeed: 0.3
};

@NgModule({
  declarations: [
    DashboardComponent,
    CalendarComponent,
    UsersComponent,
    ProjectsComponent,
    IntegrationsComponent,
    CompteComponent,
    QuizComponent,
    DetailsQuizComponent,
    TitleComponent,
    QcmComponent,
    RangesliderComponent,
    TextareaComponent,
    FilesComponent,
    ContactComponent,
    DetailsComponent,
    DetailsProjectComponent,
    ClientComponent,
    ArchitectComponent,
    DetailsArchitectProjectComponent,
    AdminComponent,
    DetailsProjectAdminComponent,
    DetailsProjectUserComponent,
    DesignersComponent,
    LinksComponent,
    RankComponent,
    ChatComponent,
    PaiementComponent,
    QuizColorsConfiguratorComponent,
    CouponsComponent,
    ProfileComponent,
    SuccessPaymentComponent,
    FailurePaymentComponent,
    CopyQuizComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    WidgetModule,
    UiSwitchModule,
    Ng5SliderModule,
    NgSelectModule,
    NgbCollapseModule,
    HttpClientModule,
    ColorPickerModule,
    NgbCarouselModule,
    NgbModule,
    DragDropModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule { }
