<div class="container-fluid">
  <app-pagetitle title="Compte"></app-pagetitle>

  <div class="card mt-2">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12 ">
          <nav class="nav nav-borders" ngbNav #justifiednav="ngbNav" [activeId]="sectionId">
            <div [ngbNavItem]="0">
              <a class="nav-link ms-0 mb-3" [class]="{'active':sectionId == 0}" (click)="selectTab( 0 )">Profil</a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-4" *ngIf="user.type == 'customer'">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Logo
                      </div>
                      <div class="card-body text-center">
                        <img *ngIf="previewUrl" [src]="previewUrl" class="logo-account mb-2" alt="logo" />
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" id="customFile" (change)="fileProgress($event)"
                            style="cursor:pointer;">
                          <label class="custom-file-label" for="customFile">Choisir un logo</label>
                        </div>
                        <!-- <button type="submit" class="btn btn-primary"> Enregistrer </button> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Informations du compte
                      </div>
                      <div class="card-body">
                        <form class="needs-validation" [formGroup]="formInfo" (ngSubmit)="postInfo()">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Prénom *</b></label>
                                <input type="text" class="form-control" formControlName="firstName"
                                  [ngClass]="{'is-invalid': submitInfo && fInfo.firstName.errors}" />
                                <div *ngIf="submitInfo && fInfo.firstName.errors" class="invalid-feedback">
                                  <span *ngIf="fInfo.firstName.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Nom *</b></label>
                                <input type="text" class="form-control" formControlName="lastName"
                                  [ngClass]="{'is-invalid': submitInfo && fInfo.lastName.errors}" />
                                <div *ngIf="submitInfo && fInfo.lastName.errors" class="invalid-feedback">
                                  <span *ngIf="fInfo.lastName.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Email *</b></label>
                                <input type="email" class="form-control" formControlName="email"
                                  [ngClass]="{'is-invalid': submitInfo && fInfo.email.errors}" />
                                <div *ngIf="submitInfo && fInfo.email.errors" class="invalid-feedback">
                                  <span *ngIf="fInfo.email.errors.required">Champ obligatoire.</span>
                                  <span *ngIf="fInfo.email.errors.pattern">Email non valide.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary jet-font" type="submit">
                              <i class="far fa-address-card"></i>
                              {{isActiveInfo?'Enregistrer':'Modifier'}}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </div>
            <div [ngbNavItem]="1">
              <a class="nav-link ms-0 mb-3" [class]="{'active':sectionId == 1}" (click)="selectTab( 1 )">Sécurité</a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-8">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Mot de passe
                      </div>
                      <div class="card-body">
                        <form class="needs-validation" [formGroup]="formPwd" (ngSubmit)="postPwd()">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Ancien mot de passe *</b></label>
                                <input type="password" class="form-control" placeholder="" formControlName="oldpassword"
                                  [ngClass]="{'is-invalid': submitFC && fC.oldpassword.errors}" />
                                <div *ngIf="submitFC && fC.oldpassword.errors" class="invalid-feedback">
                                  <span *ngIf="fC.oldpassword.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Nouveau mot de passe *</b></label>
                                <input type="password" class="form-control" placeholder=" " formControlName="password"
                                  [ngClass]="{'is-invalid': submitFC && fC.password.errors}" />
                                <div *ngIf="submitFC && fC.password.errors" class="invalid-feedback">
                                  <span *ngIf="fC.password.errors.required">Champ obligatoire.</span>
                                  <span *ngIf="fC.password.errors.minlength">Le mot de passe doit être au moins 6
                                    caractères.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="card-title"><b>Confirmation du mot de passe *</b></label>
                                <input type="password" class="form-control" placeholder="" formControlName="confirmpwd"
                                  [ngClass]="{'is-invalid': submitFC && fC.confirmpwd.errors}" />
                                <div *ngIf="submitFC && fC.confirmpwd.errors" class="invalid-feedback">
                                  <span *ngIf="fC.confirmpwd.errors.required">Champ obligatoire.</span>
                                  <span *ngIf="fC.confirmpwd.errors.mustMatch">Le champ de confirmation mot de passe ne
                                    correspond pas.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary jet-font" type="submit">
                              <i class="fas fa-unlock-alt"></i>
                              {{isActivePwd? 'Enregistrer':'Modifier mot de passe'}}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div [ngbNavItem]="2" *ngIf="user.type == 'customer'">
              <a class="nav-link ms-0 mb-3" [class]="{'active':sectionId == 2}" (click)="selectTab( 2 )">Paramètres</a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Préférences de notification
                      </div>
                      <div class="card-body pt-4">
                        <form class="needs-validation" [formGroup]="formNotifs" (ngSubmit)="updateNotifs()">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-check mb-2">
                                <input class="form-check-input" id="notif_new_project" name="notif_new_project"
                                  type="checkbox" [(ngModel)]="settings.notif_new_project" [disabled]="!isActiveNotifs"
                                  [ngModelOptions]="{standalone: true}" checked="">
                                <label class="form-check-label" for="notif_new_project">Nouveau projet</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-check mb-2">
                                <input class="form-check-input" id="notif_delivred_project"
                                  name="notif_delivred_project" type="checkbox" [disabled]="!isActiveNotifs"
                                  [(ngModel)]="settings.notif_delivred_project" [ngModelOptions]="{standalone: true}"
                                  checked="">
                                <label class="form-check-label" for="notif_delivred_project">Projet livré</label>
                              </div>
                            </div>
                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary jet-font" type="submit">
                              <i class="fas fa-unlock-alt"></i>
                              {{isActiveNotifs? 'Enregistrer':'Modifier'}}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Paiement du service
                      </div>
                      <div class="card-body pt-4">
                        <form class="needs-validation" [formGroup]="formPayment" (ngSubmit)="updatePayment()">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-check mb-2">
                                <input class="form-check-input" id="enabled_payment" name="enabled_payment"
                                  type="checkbox" [(ngModel)]="settings.enabled_payment"
                                  [ngModelOptions]="{standalone: true}" checked="" [disabled]="!isActivePayment">
                                <label class="form-check-label" for="enabled_payment">Paiment par projet</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="input-group">
                                  <input type="number" min='1' class="form-control" [(ngModel)]="settings.cost_project"
                                    name="cost_project" id="cost_project" [ngModelOptions]="{standalone: true}"
                                    [disabled]="!isActivePayment">
                                  <div class="input-group-append">
                                    <span class="input-group-text">€</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">

                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary jet-font" type="submit">
                              <i class="fas fa-unlock-alt"></i>
                              {{isActivePayment? 'Enregistrer':'Modifier'}}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div [ngbNavItem]="3" *ngIf="user.type == 'customer'">
              <a class="nav-link ms-0 mb-3" [class]="{'active':sectionId == 3}" (click)="selectTab( 3 )">Facturation</a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-8">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Adresse de facturation
                      </div>
                      <div class="card-body pt-4">
                        <form class="needs-validation" [formGroup]="form" (ngSubmit)="post()">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="firstname">Prénom *</label>
                                <input type="text" class="form-control" id="firstname" formControlName="firstname"
                                  placeholder="Prénom" [ngClass]="{'is-invalid': submit && f.firstname.errors}">
                                <div *ngIf="submit && f.firstname.errors" class="invalid-feedback">
                                  <span *ngIf="f.firstname.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="lastname">Nom *</label>
                                <input type="text" class="form-control" id="lastname" formControlName="lastname"
                                  placeholder="Nom" [ngClass]="{'is-invalid': submit && f.lastname.errors}">
                                <div *ngIf="submit && f.lastname.errors" class="invalid-feedback">
                                  <span *ngIf="f.lastname.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="phone">Téléphone *</label>
                                <input type="text" class="form-control" id="phone" formControlName="phone"
                                  placeholder="Téléphone" [ngClass]="{'is-invalid': submit && f.phone.errors}">
                                <div *ngIf="submit && f.phone.errors" class="invalid-feedback">
                                  <span *ngIf="f.phone.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="company">Entreprise *</label>
                                <input type="text" class="form-control" id="company" formControlName="company"
                                  placeholder="Entreprise" [ngClass]="{'is-invalid': submit && f.company.errors}">
                                <div *ngIf="submit && f.company.errors" class="invalid-feedback">
                                  <span *ngIf="f.company.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="email">Adresse email *</label>
                                <input id="email" type="email" class="form-control" formControlName="email"
                                  [ngClass]="{'is-invalid': submit && f.email.errors}" placeholder="Adresse email" />
                                <div *ngIf="submit && f.email.errors" class="invalid-feedback">
                                  <span *ngIf="f.email.errors.required">Champ obligatoire.</span>
                                  <span *ngIf="f.email.errors.pattern">Email non valide.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="city">Ville *</label>
                                <input type="text" class="form-control" id="city" formControlName="city"
                                  placeholder="Ville" [ngClass]="{'is-invalid': submit && f.city.errors}">
                                <div *ngIf="submit && f.city.errors" class="invalid-feedback">
                                  <span *ngIf="f.city.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="zip">Code postal *</label>
                                <input type="text" class="form-control" id="zip" formControlName="zip"
                                  placeholder="Code postal" [ngClass]="{'is-invalid': submit && f.zip.errors}">
                                <div *ngIf="submit && f.zip.errors" class="invalid-feedback">
                                  <span *ngIf="f.zip.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="country">Pays *</label>
                                <input type="text" class="form-control" id="country" formControlName="country"
                                  placeholder="Pays" [ngClass]="{'is-invalid': submit && f.country.errors}">
                                <div *ngIf="submit && f.country.errors" class="invalid-feedback">
                                  <span *ngIf="f.country.errors.required">Champ obligatoire.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary jet-font" type="submit">
                              <i class="fas fa-address-book"></i>
                              {{isActiveAdress?'Enregistrer':'Modifier adresse'}}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card mt-2">
                      <div class="card-header jet-font text-uppercase font-weight-normal">
                        Factures
                      </div>
                      <div class="card-body pt-4">
                        <div class="row ml-5 pt-1">
                          <div class="col-md-10">
                            <div class="form-group">
                              <ng-select [items]="FactureValue"></ng-select>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                          <div class="col-auto">
                            <button class="btn btn-primary jet-font">
                              <i class="fas fa-download"></i>
                              Télécharger</button>
                          </div>
                          <div class="col-auto">
                            <button class="btn btn-primary jet-font">
                              <i class="fas fa-file"></i>
                              Visualiser</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <!-- <a class="nav-link" href="#">Sécurité</a>
            <a class="nav-link" href="#">Notifications</a>
            <a class="nav-link" href="#">Facturation</a> -->
          </nav>
          <div class="pb-5" [ngbNavOutlet]="justifiednav"></div>
        </div>
      </div>
    </div>
  </div>
