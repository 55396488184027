import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { WS } from 'src/app/core/services/ws.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsProjectComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  project;
  term;
  sectionId = 0;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  settings: any = {};
  light: any;
  visitURL : any;

  constructor(
    private route: ActivatedRoute,
    public ws: WS,
    private domSanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Utilisateurs', active: true }];

    this.route.params.subscribe((params) => {
      this.ws.get('projects/' + params.id)
        .subscribe(
          data => { 
            this.project = data;
            this.visitURL = this.getURL();
          }
        )
    });

    this.route.params.subscribe((params) => {
      this.ws.get('projects/' + params.id)
        .subscribe(
          data => { this.project = data; }
        )
    });
  }

  getStatusName(status): string{
    return {
      'new':'Nouveau',
      'draft':'Brouillon',
      'sended':'Envoyé',
      'in-progress' : 'En cours',
      'pending' : 'En cours',
      'completed':'Terminé'
    }[status];
  }

  selectTab(sectionId: number): void {
    this.sectionId = sectionId;
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
          this.light = this.lightOrDark(this.settings.primary_color);
        }
      );
  }

  lightOrDark(color: any) {
    var r: number, g: number, b: number, hsp: number;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'
      )
      );

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return true;
    }
    else {

      return false;
    }
  }
  getURL(): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.project.visit);
  }
}

