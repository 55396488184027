import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { UsersComponent } from './users/users.component';
import { DetailsComponent } from './users/details/details.component';
import { DesignersComponent } from './designers/designers.component';

import { ProjectsComponent } from './projects/projects.component';
import { DetailsArchitectProjectComponent } from './projects/architect/details/details.component';
import { DetailsProjectComponent } from './projects/client/details/details.component';
import { DetailsProjectAdminComponent } from './projects/admin/details/details.component';
import { DetailsProjectUserComponent } from './projects/user/details/details.component';

import { CompteComponent } from './compte/compte.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { QuizComponent } from './quiz/quiz.component';
import { DetailsQuizComponent } from './quiz/details/details.component';
import { CopyQuizComponent } from './quiz/copy/copy-quiz/copy-quiz.component';

import { PaiementComponent } from './paiement/paiement.component';
import { CouponsComponent } from './coupons/coupons.component';
import { ProfileComponent } from './profile/profile.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { FailurePaymentComponent } from './failure-payment/failure-payment.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'utilisateurs', component: UsersComponent },
  { path: 'utilisateurs/:id', component: DetailsComponent },
  { path: 'designers', component: DesignersComponent },
  { path: 'designers/:id', component: DetailsComponent },
  { path: 'projets', component: ProjectsComponent },
  { path: 'projets/:id', component: DetailsProjectComponent },
  { path: 'projets/designer/:id', component: DetailsArchitectProjectComponent },
  { path: 'projets/admin/:id', component: DetailsProjectAdminComponent },
  { path: 'projets/utilisateur/:id', component: DetailsProjectUserComponent },
  { path: 'informations-personnelles', component: CompteComponent },
  { path: 'integration', component: IntegrationsComponent },
  { path: 'questionnaire', component: QuizComponent },
  { path: 'questionnaire/details/:id', component: DetailsQuizComponent },
  { path: 'questionnaire/copie', component: CopyQuizComponent },
  { path: 'paiement/:id', component: PaiementComponent },
  { path: 'coupons', component: CouponsComponent },
  { path: 'profil', component: ProfileComponent },
  { path: 'payment_success', component: SuccessPaymentComponent },
  { path: 'payment_error', component: FailurePaymentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
