import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { WS } from 'src/app/core/services/ws.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-copy-quiz',
  templateUrl: './copy-quiz.component.html',
  styleUrls: ['./copy-quiz.component.scss']
})
export class CopyQuizComponent implements OnInit {
  form: FormGroup;
  quizzes: any = [];
  submit = false;
  constructor(public formBuilder: FormBuilder, public ws: WS) { }

  ngOnInit(): void {
    this.getQuizzes();
    this.form = this.formBuilder.group({
      source: ['', [Validators.required]],
      dest: ['', [Validators.required]],
      budget: [],
    });

  }
  get f() { return this.form.controls; }

  getQuizzes() {
    this.ws.get('quiz')
      .subscribe(
        data => {
          this.quizzes = data;
        }
      );
  }
  post() {
    if (this.form.invalid) {
      return;
    }

    this.ws.post('quiz/copy/' + this.form.controls["source"].value + '/' + this.form.controls['dest'].value, this.form.value)
      .subscribe(
        data => {
          Swal.fire({
            title: 'Questionnaire copié avec succès.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ok'
          });
        },
        error => {
          if (error == 'Espaces similaires') {
            Swal.fire({
              title: error,
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'ok'
            });
          } else {
            Swal.fire({
              title: 'Erreur serveur.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'ok'
            });
          }
        });
    this.form.reset();
  }
}
