import { Component, OnInit } from '@angular/core';
import { WS } from '../../core/services/ws.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {

  settings: any = {};

  constructor(public ws: WS,
    private router: Router) { }

  ngOnInit(): void {
    this.getSettings();
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
        }
      );
  }

  navigation() {
    var data = { message: 'close' };
    var event = new CustomEvent('myCustomEvent', { detail: data });
    window.parent.document.dispatchEvent(event);
  }
}
