<div class="card"
    [ngClass]="{'photo': question.options.photo}"
>
  <div class="card-body">

    <textarea placeholder="Votre question" [(ngModel)]="question.title" class="form-control mb-4"></textarea>

    <div *ngFor="let item of reponses; let index= index">

      <div class="row">

        <div class="offset-md-1 col-auto">
          <div class="photo-chooser" 
               #ref{{index}} 
               [style.backgroundImage]="'url('+ ( previewUrl[index] ? previewUrl[index] : item.url) +')'"
               >
            <i *ngIf="!item.url" class="fa fa-camera"></i>
            <input type="file" name="photo" (change)="onFileChanged( $event, index)">
          </div>
        </div>
        <div class="col-md-8">
          <textarea placeholder="Reponse" [(ngModel)]="item.value" name="item.name"
            class="form-control mb-4"></textarea>
        </div>
        <div class="col-md-1" *ngIf="reponses.length>2">
          <div class="btn btn-default" (click)="deleteResponse(index)">
            <i class="fa fa-trash"></i>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="offset-md-1 col-md-9">
        <button (click)="addResponse()" class="btn btn-white b w-full">Cliquez pour ajouter une réponse</button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="required{{ question.id }}" [(ngModel)]="question.options.required"
            class="custom-control-input">
          <label for="required{{ question.id }}" class="custom-control-label">Obligatoire</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="texte_secondaire{{ question.id }}" [(ngModel)]="question.text_indicative_enabled"
            class="custom-control-input">
          <label for="texte_secondaire{{ question.id }}" class="custom-control-label">Texte secondaire</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="multiple{{ question.id }}" [(ngModel)]="question.options.multiple"
            class="custom-control-input">
          <label for="multiple{{ question.id }}" class="custom-control-label">Multiple</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="photo{{ question.id }}" [(ngModel)]="question.options.photo"
            class="custom-control-input">
          <label for="photo{{ question.id }}" class="custom-control-label">Photo</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="style_pinterest{{ question.id }}" [(ngModel)]="question.options.style_pinterest"
            class="custom-control-input">
          <label for="style_pinterest{{ question.id }}" class="custom-control-label">Style pinterest</label>
        </div>
      </div>

      <div class="col-auto" *ngIf="!question.options.multiple">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="conditionel{{ question.id }}" [(ngModel)]="question.options.conditionel"
            class="custom-control-input">
          <label for="conditionel{{ question.id }}" class="custom-control-label">Conditionel</label>
        </div>
      </div>


    </div>
    <div class="col-md-12" *ngIf="question.text_indicative_enabled">
      <input type="text" placeholder="texte secondaire" [(ngModel)]="question.text_indicative" class="form-control mb-4">
    </div>

    <div class="col-md-12" *ngIf="question.options.conditionel">
      <div class="row">
        <div class="col-4">
          <label for="">Question</label>
          <select class="form-control" [(ngModel)]="question.related_to">
            <option value=""></option>
            <option value="{{question.id}}" *ngFor="let question of questions">{{ question.title }}</option>
          </select>
        </div>
        <div class="col-4">
          <label for="">Valeur de visibilité</label>
          <select class="form-control w-50" [(ngModel)]="question.related_to_value">
            <option value=""></option>
            <option value="{{ reponse.value }}" *ngFor="let reponse of reponses">{{ reponse.value }}</option>

          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer bg-white text-right">
    <div class="btn btn-primary" (click)="save()">Enregistrer</div>
  </div>
  <div class="id">{{ getQuestionType(question.type) }}</div>
</div>
