<div class="modal-header">

</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger w-md waves-effect waves-light" (click)="modalRef.close('ok')">Annuler</button>
  <button type="button" class="btn btn-primary w-md waves-effect waves-light" (click)="this.navigation();modalRef.close('ok')">Valider</button>
</div>
