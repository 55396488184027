import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent  {

  public message: string;
  public navigateTo: string;
  public navigationFn = false;
  constructor(public modalRef: NgbActiveModal,private router: Router) { }

  navigation(): void {
    if (this.navigationFn) {
      this.router.navigate([this.navigateTo])
    }else {
      return ;
    }
  }
}
