<div class="row">
	<div class="col">
		<div class="input-group">
			<label class="pt-2 pr-2"> Couleur primaire </label>
			<input type="text" class="form-control" style="width:90px" [(ngModel)]="this.setting.primary_color">
			<span class="input-group-append">
				<span class="input-group-text colorpicker-input-addon" [style.background]="this.setting.primary_color"
					[(colorPicker)]="this.setting.primary_color" [cpPosition]="'left'"
				(colorPickerSelect)="updateColor()"><i></i></span>
			</span>
		</div>
	</div>
	<div class="col-auto">
		<div class="input-group">
			<label class="pt-2 pr-2"> Couleur secondaire </label>
			<input type="text" class="form-control" style="width:90px" [value]="this.setting.secondary_color">
			<span class="input-group-append">
				<span class="input-group-text colorpicker-input-addon" [style.background]="this.setting.secondary_color"
					[(colorPicker)]="this.setting.secondary_color" [cpPosition]="'left'"
				(colorPickerSelect)="updateColor()"><i></i></span>
			</span>
		</div>
	</div>
	<div class="col-auto"> 
		<a routerLink="/questionnaire" class="btn btn-primary jet-font" *ngIf="router.url !== '/questionnaire'" style="width: 120px;"> 
			<i class="fa fa-arrow-left"></i> 
			Retour
		  </a>
	</div>
	<div class="col-auto"> 
		<a href="./apercu" target="_blank" class="btn btn-warning jet-font" style="width: 120px;"> 
			<i class="fa fa-eye"></i> 
			Aperçu
		</a>
	</div>
</div>