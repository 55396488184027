<div class="container-fluid">

  <div class="row mb-5">
    <div class="col">
      <app-pagetitle title="Questionnaire"></app-pagetitle>
    </div>
    <div class="col-auto">
      <app-quiz-colors-configurator></app-quiz-colors-configurator>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-xl-2">

      <form class="card pt-2 add-quiz" (ngSubmit)="addQuiz()">
        <div class="card-photo card-body">

          <div class="center card-photo">
            <img [src]="quiz.photo || 'assets/images/placeholder.jpg'" class="img-thumbnail rounded-circle avatar-xl">
            <label class="icon-change-photo">
              <i class="fa fa-camera"></i>
              <input type="file" class="file-input" (change)="fileProgress($event, quiz)">
            </label>
          </div>

          <div class="form-group px-3 mt-3">
            <input type="text" class="form-control border-0 bg-light form-control-sm text-center" required
              [(ngModel)]="name" name="name" placeholder="Espace">
          </div>
        </div>
        <div class="text-right p-2">
          <button class="btn btn-sm btn-primary" type="submit">Créer</button>
        </div>
      </form>
    </div>
    <div class="col-md-6 col-xl-2" *ngFor="let quiz of quizzes">
      <div class="card pt-4">
        <div class="center card-photo">
          <img [src]="quiz.photo || 'assets/images/placeholder.jpg'" class="img-thumbnail rounded-circle avatar-xl">
          <label class="icon-change-photo">
            <i class="fa fa-camera"></i>
            <input type="file" class="file-input" (change)="fileProgress($event, quiz)">
          </label>
        </div>
        <div class="card-body text-center">
          <h4 class="card-title mt-0">
            <a [routerLink]="['/questionnaire/details/', quiz.id]">{{quiz.name}}</a>
          </h4>
        </div>
        <div class="text-right p-2">
          <button class="btn btn-sm btn-danger" (click)="deleteQuiz(quiz.id)">Supprimer</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="quizzes.length > 0">
    <div class="row mt-3 mb-2">
      <div class="col">
        <div class="page-title-box d-flex align-items-center justify-content-between"> 
          <h4 class="mb-0">Ordre des espaces</h4>
        </div>
      </div>
    </div>
    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list col mb-5" (cdkDropListDropped)="drop($event)">
      <div class="example-box m-auto" *ngFor="let quiz of quizzes; let i = index" cdkDrag>
        <h4 class="card-title">
          {{i+1}} - {{quiz.name}}
        </h4>
      </div>
    </div>
  </div>
</div>
