import { Component, OnInit } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  projects: any[];
  term: string;

  constructor(
    public ws: WS
  ) {
  }
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Projects', active: true }];
    this.ws.get('projects')
      .subscribe(
        data => this.projects = data
      );
  }
  getStatusName(status): string{
    return {
      'new':'Nouveau',
      'draft':'Brouillon',
      'sended':'Envoyé',
      'in-progress' : 'En cours',
      'pending' : 'En cours',
      'completed':'Terminé'
    }[status];
  }
}
