import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from '../../../../core/services/authfake.service';
import { Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsProjectUserComponent implements OnInit {
  project;
  user: any = null;
  settings: any = {};
  light: any;
  sectionId = 0;
  visitURL : any; 
  designer : any = null;
  constructor(
    private route: ActivatedRoute,
    public ws: WS,
    public authFackservice: AuthfakeauthenticationService,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getSettings();
    this.getConnectedUser();
    this.route.params.subscribe((params) => {
      this.ws.get('projects/' + params.id)
        .subscribe(
          data => { 
            this.project = data;
            if (this.project.designer_id){
              this.getDesigner(this.project.designer_id)
            } else {
              this.getDesigner(-1);
            }
            this.visitURL = this.getURL();
          }
        )
    });
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
          this.light = this.lightOrDark(this.settings.primary_color);
        }
      );
  }

  getDesigner(id) {
    this.ws.get('customers/find/' + id)
      .subscribe(
        data => {
          this.designer = data;
        }
      );
  }
  getConnectedUser(): void {
    this.ws.get('authenticated')
      .subscribe(
        data => {
          this.user = data;
        })
  }

  logout(): void {
    this.authFackservice.logout();
    this.router.navigate(['/compte/connexion']);
  }

  getStatusName(status): string {
    return {
      'new': 'Nouveau',
      'draft': 'Brouillon',
      'sended': 'Envoyé',
      'in-progress': 'En cours',
      'pending' : 'En cours',
      'completed': 'Terminé'
    }[status];
  }
  lightOrDark(color: any) {
    var r: number, g: number, b: number, hsp: number;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'
      )
      );

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return true;
    }
    else {

      return false;
    }
  }

  selectTab(sectionId: number): void {
    this.sectionId = sectionId;
  }

  getURL(): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.project.visit);
  }
}

