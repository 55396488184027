<!-- <app-loader></app-loader> -->
<div class="container-fluid">
  <app-pagetitle title="Calendar"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="app-calendar">
            <!-- calendar -->
            <full-calendar #calendar defaultView="dayGridMonth" [header]="{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }" [plugins]="calendarPlugins" [eventResizableFromStart]="'true'" [eventLimit]="true" [editable]="true"
              themeSystem='bootstrap' [weekends]="calendarWeekends" [bootstrapFontAwesome]="false"
              deepChangeDetection="true" [events]="calendarEvents" (dateClick)="openModal(content, $event)"
              (eventClick)="openEditModal(editcontent, $event)">
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Event Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add New Event</h5>
    <button type="button" class="close" aria-hidden="true" (click)="closeEventModal()">×</button>
  </div>
  <div class="modal-body p-3">
    <form (ngSubmit)="saveEvent()" [formGroup]="formData">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="control-label">Event Name</label>
            <input class="form-control" placeholder="Insert Event Name" type="text" name="title" formControlName="title"
              [ngClass]="{'is-invalid': submitted && form.title.errors}">
            <div *ngIf="submitted && form.title.errors" class="invalid-feedback">
              <span *ngIf="form.title.errors.required">This value is required.</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group"><label class="control-label">Category</label><select class="form-control"
              name="category" formControlName="category" [ngClass]="{'is-invalid': submitted && form.category.errors }">
              <option *ngFor="let option of category" value="{{ option.value }}"> {{ option.name }}
              </option>
            </select>
            <div *ngIf="submitted && form.category.errors" class="invalid-feedback">
              <div *ngIf="form.category.errors.required">This value is required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right pt-4">
        <button type="button" class="btn btn-light" (click)="closeEventModal()">Close</button>
        <button type="submit" class="btn btn-success save-event ml-1">Save</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Edit event modal -->
<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Edit Event</h5>
    <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
  </div>
  <div class="modal-body p-3">
    <form [formGroup]="formEditData" (ngSubmit)="editEventSave()">
      <label>Change event name</label>
      <div class="input-group m-b-15">
        <input class="form-control" type="text" formControlName="editTitle">
      </div>
      <label class="mt-2">Change event category</label>
      <div class="input-group m-b-15">
        <select class="form-control" name="ecategory" formControlName="editCategory">
          <option *ngFor="let option of category" value="{{ option.value }}"> {{ option.name }}
          </option>
        </select>
      </div>
    </form>
  </div>
  <div class="text-right p-3">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Close')">Close</button>
    <button type="button" class="btn btn-danger delete-event ml-1" (click)="confirm()">Delete</button>
    <button type="button" class="btn btn-success ml-1" (click)="editEventSave()">Save</button>
  </div>
</ng-template>
