<div class="container-fluid">
  <app-pagetitle title="Projets"></app-pagetitle>

  <div class="row">

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-right pt-2" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle
              data-toggle="dropdown" aria-expanded="false">
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a href="javascript:void(0);" class="dropdown-item">Exporter</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2" [(ngModel)]="term" /></label></div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered">
              <thead class="thead-light">
                <tr>
                  <th>Reférence</th>
                  <th>Date de création</th>
                  <th>Client</th>
                  <th>Objet</th>
                  <th>Nombre de produits</th>
                  <th>Statut</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of projects|filter:term">
                  <td><a [routerLink]="['/projets/designer/', item.id]">{{item.reference}}</a></td>
                  <td>{{item.created_at | date}}</td>
                  <td>{{item.customer.fullname}}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.products.length}}</td>
                  <td>
                    <div class="badge font-size-12" [ngClass]="{
                          'badge-soft-primary': item.status === 'new',
                          'badge-soft-success':item.status === 'completed',
                          'badge-soft-danger': item.status === 'pending',
                          'badge-soft-warning': item.status === 'in-progress'}">
                      {{getStatusName(item.status)}}
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
