import { Component, OnInit } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-designers',
  templateUrl: './designers.component.html',
  styleUrls: ['./designers.component.scss']

})
export class DesignersComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  designers: any[];
  term: string;
  user: any;

  constructor(
    public ws: WS,
    private authFackservice: AuthfakeauthenticationService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.user = this.authFackservice.currentUserValue;
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Architectes', active: true }];

    this.ws.get('designers')
      .subscribe(
        data => { this.designers = data; console.log(data) }
      );
  }

  connectAs(id): void {
    this.authFackservice.connectAs(id)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        });
  }

}
