<div class="card">
  <div class="card-body">
    <textarea placeholder="Votre question" [(ngModel)]="question.title" class="form-control mb-4"></textarea>
    <textarea placeholder="Texte à afficher" [(ngModel)]="question.options.placeholder" class="form-control"></textarea>
    <hr>
    <div class="row">
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="required{{ question.id }}" [(ngModel)]="question.options.required"
            class="custom-control-input">
          <label for="required{{ question.id }}" class="custom-control-label">Obligatoire</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="texte_secondaire{{ question.id }}" [(ngModel)]="question.text_indicative_enabled"
            class="custom-control-input">
          <label for="texte_secondaire{{ question.id }}" class="custom-control-label">Texte secondaire</label>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="question.text_indicative_enabled">
      <textarea placeholder="Texte secondaire" [(ngModel)]="question.text_indicative" class="form-control"></textarea>
    </div>
  </div>
  <div class="card-footer bg-white text-right">
    <div class="btn btn-primary" (click)="save()">Enregistrer</div>
  </div>
  <div class="id">{{ getQuestionType(question.type) }}</div>
</div>
