import { Component, OnInit } from '@angular/core';
import { WS } from '../../core/services/ws.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-failure-payment',
  templateUrl: './failure-payment.component.html',
  styleUrls: ['./failure-payment.component.scss']
})
export class FailurePaymentComponent implements OnInit {

  settings: any = {};

  constructor(public ws: WS,
    private router: Router) { }

  ngOnInit(): void {
    this.getSettings();
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
        }
      );
  }

  navigation() {
    var data = { message: 'close' };
    var event = new CustomEvent('myCustomEvent', { detail: data });
    window.parent.document.dispatchEvent(event);
  }

}
