import { Component, OnInit, Input } from '@angular/core';
import { WS } from 'src/app/core/services/ws.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-quiz-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() question: any;
  enableIndicative: boolean;
  indicativeText: string;
  constructor(private toastr: ToastrService, public ws: WS) { }

  ngOnInit(): void {
  }

  save(): void {
    this.ws.put('questions/' + this.question.id, this.question)
      .subscribe(data => {
        // this.toastr.success('Mise à jour avec succès');
      });
  }
  getQuestionType(type): string{
    return {
      'title':'Titre',
      'radio':'QCM / QCU',
      'slider':'Intervalle',
      'textarea':'Ouverte',
      'photos':'Fichiers',
      'links':'Liens',
      'rank':'Évaluation'
    }[type];
  }
}


