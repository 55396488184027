import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private accessTokenSubject: BehaviorSubject<string>;
  public accessToken: Observable<string>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<string>(localStorage.getItem('access_token'));
    this.accessToken = this.accessTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get accessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  login(email: string, password: string , remember_me? : any, temp_user? : number, temp_project_id? : number) {
    return this.http.post<any>(environment.apiUri + 'login', { email, password , remember_me, temp_user, temp_project_id})
      .pipe(map(data => {

        // login successful if there's a jwt token in the response
        if (data && data.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          this.currentUserSubject.next(data.user);
          this.accessTokenSubject.next(data.access_token);
        }
        return data;
      }));
  }

  register(firstname: string, lastname: string, email: string, password: string, temp_user? : number) {
    return this.http.post<any>(environment.apiUri + 'register', { firstname, lastname, email, password , temp_user})
      .pipe(map(data => {
        if (data && data.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          this.currentUserSubject.next(data.user);
          this.accessTokenSubject.next(data.access_token);
        }
        return data;
      }));
  }

  connectAs(id: any) {
    return this.http.post<any>(environment.apiUri + 'irkpwkrj/' + id, {})
      .pipe(map(data => {
        // login successful if there's a jwt token in the response
        if (data && data.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          this.currentUserSubject.next(data.user);
          this.accessTokenSubject.next(data.access_token);
        }
        return data;
      }));
  }
  authenticated() {
    return this.http.get<any>(environment.apiUri + 'authenticated')
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    this.http.get<any>(environment.apiUri + 'logout');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    this.currentUserSubject.next(null);
  }

  sendResetPasswordLink(email: string) {
    return this.http.post<any>(environment.apiUri + 'reset-password-request', { email });
  }

  resetPassword(data: any) {
    return this.http.post<any>(
      environment.apiUri + 'change-password',
      data
    );
  }

  reset() {
    this.http.get<any>(environment.apiUri + 'logout');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    this.currentUserSubject.next(null);
  }

  confirm(code: string) {
    return this.http.post<any>(environment.apiUri + 'confirm', { code })
    .pipe(map(data => {
      // login successful if there's a jwt token in the response
      if (data && data.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('currentUser', JSON.stringify(data.user));
        this.currentUserSubject.next(data.user);
        this.accessTokenSubject.next(data.access_token);
      }
      return data;
    }));
  }

}