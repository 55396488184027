<div class="card">
  <div class="card-body">
    <textarea placeholder="Votre question" [(ngModel)]="question.title" class="form-control mb-4"></textarea>
    <hr>
    <div class="row">

      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="required{{ question.id }}" [(ngModel)]="question.options.required"
            class="custom-control-input">
          <label for="required{{ question.id }}" class="custom-control-label">Obligatoire</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="texte_secondaire{{ question.id }}" [(ngModel)]="question.text_indicative_enabled"
            class="custom-control-input">
          <label for="texte_secondaire{{ question.id }}" class="custom-control-label">Texte secondaire</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="multiple{{ question.id }}" [(ngModel)]="question.options.multiple"
            class="custom-control-input">
          <label for="multiple{{ question.id }}" class="custom-control-label">Plusieurs</label>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="question.text_indicative_enabled">
      <textarea placeholder="Texte secondaire" [(ngModel)]="question.text_indicative" class="form-control"></textarea>
    </div>
    <div class="row mt-4">
      <div class="col-auto">
        <h6>Types de fichiers à accepter</h6>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="photo{{ question.id }}" [(ngModel)]="question.options.accept_photo"
            class="custom-control-input">
          <label for="photo{{ question.id }}" class="custom-control-label">Photo</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="video{{ question.id }}" [(ngModel)]="question.options.accept_video"
            class="custom-control-input">
          <label for="video{{ question.id }}" class="custom-control-label">Vidéo</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="pdf{{ question.id }}" [(ngModel)]="question.options.accept_pdf"
            class="custom-control-input">
          <label for="pdf{{ question.id }}" class="custom-control-label">PDF</label>
        </div>
      </div>
      <!-- <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="file{{ question.id }}" [(ngModel)]="question.options.accept_file"
            class="custom-control-input">
          <label for="file{{ question.id }}" class="custom-control-label">Autre(s)</label>
        </div>
      </div> -->
    </div>
  </div>
  <div class="card-footer bg-white text-right">
    <div class="btn btn-primary" (click)="save()">Enregistrer</div>
  </div>
  <div class="id">{{ getQuestionType(question.type) }}</div>
</div>
