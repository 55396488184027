import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { WS } from 'src/app/core/services/ws.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  user;
  term;
  constructor(
    private route: ActivatedRoute,
    public ws: WS

  ) { }


  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Utilisateurs', active: true }];

    this.route.params.subscribe((params) => {
      this.ws.get('customers/' + params.id)
        .subscribe(
          data => { this.user = data; }
        )
    });


  }
  getStatusName(status): string{
    return {
      'new':'Nouveau',
      'draft':'Brouillon',
      'sended':'Envoyé',
      'in-progress' : 'En cours',
      'pending' : 'En cours',
      'completed':'Terminé'
    }[status];
  }
}

