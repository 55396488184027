import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.scss']
})
export class PaiementComponent implements OnInit {
  @ViewChild('paymentFrame') paymentFrame: ElementRef;

  project_id: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.project_id = params.id;
    });
  }
  ngAfterViewInit() {
    window.document.addEventListener('myCustomEvent', this.handleIFrameEvent, false);
  }
  // ngAfterViewInit(): void {
  //   fromEvent(this.paymentFrame.nativeElement, 'load')
  //     // Skip the initial load event and only capture subsequent events.
  //     .pipe(skip(1))
  //     .subscribe((event: Event) => console.log(event.target));
  // }
  getURL(): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(environment.apiUri + 'paiement/' + this.project_id);
  }

  handleIFrameEvent(e) {
    window.location.href="/";
  }
}
