import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  quizzes: any = [];
  quizSectionId = 1;
  name: string;

  quiz: any = {};
  user: any = null;
  fileData: File = null;

  timePeriods = [
    'Bronze age',
    'Iron age',
    'Middle ages',
    'Early modern period',
    'Long nineteenth century',
  ];

  constructor(private authFackservice: AuthfakeauthenticationService, public ws: WS) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Questionnaire', active: true }];
    this.getQuizzes();
    this.getConnectedUser()
  }

  getQuizzes() {
    this.ws.get('quiz')
      .subscribe(
        data => {
          this.quizzes = data;
        }
      );
  }

  getConnectedUser(): void {
    this.user = this.authFackservice.currentUserValue;
  }
  addQuiz() {
    if (this.name.trim() !== '') {
      this.ws.post('quiz/add', { name: this.name })
        .subscribe(
          (data: any) => {
            this.savePhoto(data.quiz);
            // this.toastr.success('Espace ajouté avec succès');
            this.getQuizzes();
            setTimeout(() => {
              this.name = '';
              this.fileData = null;
              this.quiz.photo = null;
            }, 500);
          }
        );
    }

  }
  deleteQuiz(id) {
    Swal.fire({
      title: 'Êtes-vous sûr de supprimer ce espace?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.ws.delete('quiz/' + id)
          .subscribe((data: any) => {
            // this.toastr.success('Espace supprimé avec succés');
            this.getQuizzes();
          });
      }
    });
  }

  savePhoto(quiz) {
    const formData = new FormData();
    formData.append('photo', this.fileData);

    this.ws.post('quiz/' + quiz.id + '/photo', formData)
      .subscribe(
        data => {
        }
      )
  }

  preview(quiz) {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      quiz.photo = reader.result;
      if (quiz.id) {
        this.savePhoto(quiz);
      }
    }
  }

  fileProgress(fileInput: any, quiz) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview(quiz);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quizzes, event.previousIndex, event.currentIndex);
    this.ws.get('quiz/order/' + (event.previousIndex + 1) + '/' + (event.currentIndex + 1))
      .subscribe(
        data => {
        }
      )
  }
}
