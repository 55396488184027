<div class="card">
  <div class="card-body">
    <textarea placeholder="Votre question" [(ngModel)]="question.title" class="form-control mb-4"></textarea>


    <div class="row">
      <div class="col-md-2">
        <div class="form-inline">
          <label class="mr-2">Min</label>
          <input type="text" [(ngModel)]="question.options.min" class="form-control w-75">
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-inline">
          <label class="mr-2">Max</label>
          <input type="text" [(ngModel)]="question.options.max" class="form-control w-75">
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-inline">
          <label class="mr-2">Unité</label>
          <input type="text" [(ngModel)]="question.options.signe" class="form-control w-50">
        </div>
      </div>
    </div>

    <hr>
    <div class="row">
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="required{{ question.id }}" [(ngModel)]="question.options.required"
            class="custom-control-input">
          <label for="required{{ question.id }}" class="custom-control-label">Obligatoire</label>
        </div>
      </div>

      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="texte_secondaire{{ question.id }}" [(ngModel)]="question.text_indicative_enabled"
            class="custom-control-input">
          <label for="texte_secondaire{{ question.id }}" class="custom-control-label">Texte secondaire</label>
        </div>
      </div>

      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="show_en_ligne{{ question.id }}" [(ngModel)]="question.options.show_en_ligne"
            class="custom-control-input">
          <label for="show_en_ligne{{ question.id }}" class="custom-control-label">affichage en ligne</label>
        </div>
      </div>

    </div>
    <div class="col-md-12" *ngIf="question.text_indicative_enabled">
      <textarea placeholder="Texte secondaire" [(ngModel)]="question.text_indicative" class="form-control"></textarea>
    </div>


  </div>
  <div class="card-footer bg-white text-right">
    <div class="btn btn-primary" (click)="save()">Enregistrer</div>
  </div>
  <div class="id">{{ getQuestionType(question.type) }}</div>
</div>
