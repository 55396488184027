import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WS } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-quiz-colors-configurator',
  templateUrl: './quiz-colors-configurator.component.html',
  styleUrls: ['./quiz-colors-configurator.component.scss']
})
export class QuizColorsConfiguratorComponent implements OnInit {
  
  setting: any = {};


  constructor(public ws: WS,public router: Router) { }

  ngOnInit(): void {
    this.setting.primary_color = '#ffffff';
    this.setting.secondary_color = '#ffffff';
    this.getSetting();
  }

  getSetting() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.setting = data;
        }
      );
  }

  updateColor(): void {
    this.ws.put('settings/colors', { primary: this.setting.primary_color, secondary: this.setting.secondary_color })
      .subscribe(res => { });
  }

}
