import { Component, OnInit } from "@angular/core";
import { WS } from "src/app/core/services/ws.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {
  statistics: any = {};
  projects: any = [];
  settings: any = [];
  user: any = null;
  constructor(
    public ws: WS,
    public authFackservice: AuthfakeauthenticationService,
    private router: Router
  ) {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.getStatistics();
    this.getConnectedUser();
    this.getSettings();
  }

  getStatistics(): void {
    this.ws.get("statistics").subscribe((data) => {
      this.statistics = data;
    });
  }

  getQuizForAuthenticatedUser(): void {
    this.ws.get("projects/forUserConnected").subscribe((data) => {
      this.projects = data;
      if (data.length == 0) {
        this.startNewProject();
      }
    });
  }

  getConnectedUser(): void {
    this.ws.get("authenticated").subscribe((data) => {
      this.user = data;
      if (this.user.type === "user") {
        this.getQuizForAuthenticatedUser();
      }
    });
  }

  logout(): void {
    this.authFackservice.logout();
    this.router.navigate(["/compte/connexion"]);
  }

  startNewProject(): void {
    this.ws.post("projects", {}).subscribe((data: any) => {
      this.router.navigate(["/apercu/" + data.project_id]);
    });
  }

  getStatusName(status): string {
    return {
      new: "Nouveau",
      draft: "Brouillon",
      sended: "Envoyé",
      "in-progress": "En cours",
      pending: "En cours",
      completed: "Terminé",
    }[status];
  }

  getSettings() {
    this.ws.get("settings").subscribe((data) => {
      this.settings = data;
      if (!data) {
        this.settings = {};
      }
    });
  }

  deleteProject(id): void {
    Swal.fire({
      text: "Supprimer ce projet ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        this.ws.delete("projects/" + id).subscribe((data) => {
          this.getQuizForAuthenticatedUser();
        });
      }
    });
  }
  contact(): void {
    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    Swal.fire({
      title: "Contactez-nous",
      html: `<input type="text" id="name" class="swal2-input" style="height: 35px !important;font-size: 15px !important;" placeholder="Nom et prénom*">
      <input type="email" id="email" required class="swal2-input" style="height: 35px !important;font-size: 15px !important;" placeholder="Email*">
      <input type="text" id="object" class="swal2-input" style="height: 35px !important;font-size: 15px !important;" placeholder="Objet de votre demande *">
      <textarea rows="6" id="message" class="swal2-input" style="height: 150px !important;font-size: 15px !important;" placeholder="Message *">`,
      confirmButtonText: "Envoyer",
      focusConfirm: false,
      preConfirm: () => {
        const name = (<HTMLInputElement>Swal.getPopup().querySelector("#name"))
          .value;
        const email = (<HTMLInputElement>(
          Swal.getPopup().querySelector("#email")
        )).value;
        const object = (<HTMLInputElement>(
          Swal.getPopup().querySelector("#object")
        )).value;
        const message = (<HTMLInputElement>(
          Swal.getPopup().querySelector("#message")
        )).value;

        if (!name || !email || !object || !message) {
          Swal.showValidationMessage(
            `Veuillez remplir tous les champs obligatoires`
          );
          return;
        }
        if (!validateEmail(email)) {
          Swal.showValidationMessage(`Email non valide`);
          return;
        }
        return { name: name, email: email, object: object, message: message };
      },
    }).then((result) => {
      this.ws
        .post("contact", {
          name: result.value.name,
          email: result.value.email,
          object: result.value.object,
          message: result.value.message,
        })
        .subscribe((res) => {
          Swal.fire({
            title: "Message envoyé.",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        });
    });
  }
}
