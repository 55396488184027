<div class="card">
  <div class="card-body">
    <div class="row">
      

      <div class="col-md-12">
        <input type="text" placeholder="Titre" [(ngModel)]="question.title" class="form-control mb-4">
      </div>
      
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="infobull1{{ question.id }}" [(ngModel)]="question.options.infobull1_show"
            class="custom-control-input">
          <label for="infobull1{{ question.id }}" class="custom-control-label">Infobull</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="texte_secondaire{{ question.id }}" [(ngModel)]="question.text_indicative_enabled"
            class="custom-control-input">
          <label for="texte_secondaire{{ question.id }}" class="custom-control-label">Texte secondaire</label>
        </div>
      </div>

      <div class="col-auto">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="h2{{ question.id }}" [(ngModel)]="question.options.h2"
            class="custom-control-input">
          <label for="h2{{ question.id }}" class="custom-control-label">Titre 2</label>
        </div>
      </div>

      
      <div class="col-md-12" *ngIf="question.options.infobull1_show">
        <input type="text" placeholder="Infobull Titre" [(ngModel)]="question.options.infobull1" class="form-control mb-4">
      </div>
      

      <div class="col-md-12" *ngIf="question.text_indicative_enabled">
        <input type="text" placeholder="Texte secondaire" [(ngModel)]="question.text_indicative"
          class="form-control mb-4">
      </div>

      <div class="col-12" *ngIf="question.text_indicative_enabled">
        <div class="custom-control custom-switch mb-4">
          <input type="checkbox" id="infobull2{{ question.id }}" [(ngModel)]="question.options.infobull2_show"
            class="custom-control-input">
          <label for="infobull2{{ question.id }}" class="custom-control-label">Infobull texte secondaire</label>
        </div>
      </div>
      <div class="col-md-12" *ngIf="question.text_indicative_enabled && question.options.infobull2_show">
        <input type="text" placeholder="Infobull texte secondaire" [(ngModel)]="question.options.infobull2" class="form-control mb-4">
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-primary" (click)="save()">Enregistrer</button>
    </div>
    
  </div>
  <div class="id">{{ getQuestionType(question.type) }}</div>

  <div class="icon-link" *ngIf="question.is_relatedto"> <i class="fa fa-link"></i> </div>
</div>
