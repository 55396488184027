<div class="card w-100 user-chat mt-4 mt-sm-0">
  <div class="px-lg-2">
    <div class="chat-header">
      <img class="rounded-circle chat-user-avatar" [src]="receiver.photo || 'assets/images/users/avatar.png'"
        alt="Header Avatar">
      <div class="chat-user-details" style="display:flex; flex-direction:column">
        <span *ngIf="receiver.type == 'designer' || receiver.type == 'user'">{{receiver.fullname}}</span>
        <span *ngIf="receiver.type == 'admin'">Votre designer</span>
      </div>
    </div>
    <div class="chat-conversation p-3">
      <div #scrollMe [style.overflow]="chatMessagesData.length > 0 ? 'scroll' : ''"
        [style.height]="chatMessagesData.length > 0 ? '62vh' : '40vh'">
        <ul class="list-unstyled mb-0 pr-3" *ngFor="let message of chatMessagesData"
          [ngClass]="{ 'right': message.user_id == sender.id }">
          <li *ngIf="!message.text">
            <div class="conversation-list">

              <div class="chat-avatar" *ngIf="message.image">
                <img src="{{message.image}}" alt="">
              </div>
              <div class="ctext-wrap">
                <div class="conversation-name">{{message.name}}</div>
                <div class="ctext-wrap-content chat-content">
                  <div class="mb-0" [innerHTML]="message.message">
                  </div>
                </div>
                <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i>
                  {{message.created_at | date:'dd/MM/yyyy H:mm'}}</p>
              </div>
            </div>
          </li>
          <li *ngIf="message.text">
            <div class="chat-day-title">
              <span class="title">{{message.text}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="px-lg-3" *ngIf="disabled != 1">
    <div class="p-3 chat-input-section">
      <form class="row" (ngSubmit)="send()">
        <div class="col-md-10 chat-input">
          <div class="position-relative">
            <input type="text" class="form-control chat-input" [(ngModel)]="message"
              [ngModelOptions]="{standalone: true}" placeholder="Tapez votre message ici...">

          </div>
        </div>
        <div class="col-md-1 chat-btn">
          <button type="submit" class="btn btn-primary chat-send waves-effect waves-light"><span
              class="d-none d-sm-inline-block"></span> <i class="mdi mdi-send"></i></button>
        </div>
      </form>

    </div>
  </div>
</div>
