    <div class="modal-header">
      <h5 class="modal-title mt-0">
        Modifier produit : {{ product.name }} | {{ product.reference }}
      </h5>
      <button type="button" class="close" (click)="modalRef.close('Cross click');" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="post()">
        <div class="row">
          <div class="col-md-6">
            <label>Nom *</label>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="name"
                [ngClass]="{'is-invalid': submitEditForm && f.name.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.name.errors" class="invalid-feedback">
                <span *ngIf="f.name.errors.required">Champ obligatoire.</span>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <label>Marque *</label>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="marque"
                [ngClass]="{'is-invalid': submitEditForm && f.marque.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.marque.errors" class="invalid-feedback">
                <span *ngIf="f.marque.errors.required">Champ obligatoire.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>Référence</label>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="reference"
                [ngClass]="{'is-invalid': submitEditForm && f.reference.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.reference.errors" class="invalid-feedback">
                <span *ngIf="f.reference.errors.required">Champ obligatoire.</span>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <label>Photo *</label>
            <div *ngIf="previewUrlProduct">
              <img *ngIf="previewUrlProduct" style="height: 5rem;" [src]="previewUrlProduct" class="mb-2"
                alt="photo-produit" />
            </div>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFile" (change)="fileProgressProduct($event)">
              <label class="custom-file-label" for="customFile">Choisir un fichier</label>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Lien *</label>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="link"
                [ngClass]="{'is-invalid': submitEditForm && f.link.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.link.errors" class="invalid-feedback">
                <span *ngIf="f.link.errors.required">Champ obligatoire.</span>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label>Prix *</label>
            <div class="form-group">
              <div class="input-group">
                <input type="number" formControlName="price" class="form-control"
                  [ngClass]="{'is-invalid': submitEditForm && f.price.errors}">
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div *ngIf="submitEditForm && f.price.errors" class="invalid-feedback">
                <span *ngIf="f.price.errors.required">Champ obligatoire.</span>
                <span *ngIf="f.price.errors.pattern">Numéro non valide.</span>
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <label>Hauteur</label>
            <div class="form-group">
              <input type="number" class="form-control" formControlName="hauteur"
                [ngClass]="{'is-invalid': submitEditForm && f.hauteur.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.hauteur.errors" class="invalid-feedback">
                <span *ngIf="f.hauteur.errors.required">Champ obligatoire.</span>
                <span *ngIf="f.hauteur.errors.pattern">Numéro non valide.</span>
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <label>Largeur</label>
            <div class="form-group">
              <input type="number" class="form-control" formControlName="largeur"
                [ngClass]="{'is-invalid': submitEditForm && f.largeur.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.largeur.errors" class="invalid-feedback">
                <span *ngIf="f.largeur.errors.required">Champ obligatoire.</span>
                <span *ngIf="f.largeur.errors.pattern">Numéro non valide.</span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label>Profondeur</label>
            <div class="form-group">
              <input type="number" class="form-control" formControlName="profondeur"
                [ngClass]="{'is-invalid': submitEditForm && f.profondeur.errors}" placeholder=" " />
              <div *ngIf="submitEditForm && f.profondeur.errors" class="invalid-feedback">
                <span *ngIf="f.profondeur.errors.required">Champ obligatoire.</span>
                <span *ngIf="f.profondeur.errors.pattern">Numéro non valide.</span>
              </div>
            </div>
          </div>
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal"
            (click)="modalRef.close('ok');">Annuler</button>
          <button type="submitEditForm" class="btn btn-primary">Enregistrer</button>
        </div>
      </form>
    </div>
