import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WS {
  constructor(private http: HttpClient) { }

  get(url) {
    return this.http.get<any>(environment.apiUri + url);
  }

  post(url, data, options = null) {
    if (options) {
      return this.http.post(environment.apiUri + url, data, options);
    } else {
      return this.http.post(environment.apiUri + url, data);
    }
  }

  put(url, data) {
    return this.http.put(environment.apiUri + url, data);
  }

  delete(url) {
    return this.http.delete(environment.apiUri + url);
  }

  patch(url, data) {
    return this.http.patch(environment.apiUri + url, data);
  }
}
